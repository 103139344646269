<template>
	<div :class="['tank-container', size]">
		<div class="tank-icon">
			<div class="tank-icon-bar">
				<div class="tank-icon-value" :style="tankValueHeight"></div>
			</div>
			<div class="tank-icon-bg"></div>
			<div class="tank-icon-bg-inner"></div>
		</div>
		<div class="tank-info">
			<div class="tank-info-percentage">
				<FontAwesomeIcon v-if="isLiquidLevelEmptySoonStatus" :icon="['fas', 'exclamation-circle']" />
				<span v-if="percentageText">
					{{ percentageText }}
				</span>
			</div>
			<div class="tank-info-value">
				{{ tankLevel }}<span v-html="unit"></span>
			</div>
		</div>
	</div>
</template>

<script>
import { parseAddCommaWhenIsNumber, parseToTypeNumber } from "../../../selectors/helpers/index";

export default {
	name: "TankIcon",

	props: {
		value: {
			type: [Number, String],
			default: 0
		},

		percentage: {
			type: [Number, String],
			default: 0
		},

		size: {
			type: String,
			default: "small",
			validator: (value) => {
				return [
					"smallest",
					"small",
					"large"
				].includes(value);
			}
		},

		unit: {
			type: String,
			default: ""
		},

		tankColor: {
			type: String,
			default: "#009b44"
		},

		liquidLevelEmptySoonStatus: {
			type: [Number, String],
			default: 0
		}
	},

	computed: {
		tankValueHeight() {
			let width = parseToTypeNumber(this.percentage);
			width = (width * 75) / 100; // 75 is max width% > 75 overflow Box.
			if (width < 0) {
				width = 0;
			} else if (width > 75) {
				width = 75;
			}
			return {
				width: `${width}%`,
				backgroundColor: `${this.tankColor}`
			};
		},

		tankLevel() {
			return this.value !== null ? `${parseAddCommaWhenIsNumber(this.value)} ` : ``;
		},

		isLiquidLevelEmptySoonStatus() {
			return this.liquidLevelEmptySoonStatus === 1;
		},

		percentageText() {
			return this.percentage !== null ? `${parseAddCommaWhenIsNumber(this.percentage)}%` : ``;
		}
	}
};
</script>

<style lang="scss" scoped>
.tank-container {
	display: flex;
	flex-direction: column;
}

.tank-icon {
	position: relative;
	flex: 0 0 rem(30);
	width: rem(75);
	height: rem(30);

	&-bar {
		position: relative;
		width: rem(75);
		height: rem(30);
	}

	&-value {
		position: absolute;
		bottom: 0;
		background-color: $color-emerald;
		z-index: 2;
		height: rem(18);
		top: rem(5);
		left: rem(9);
	}

	&-bg {
		position: absolute;
		top: 0;
		background: url("/assets/images/h-tank-icon.svg") no-repeat 0 0;
		background-size: rem(75) rem(30);
		width: rem(75);
		height: rem(30);
		z-index: 2;
	}

	&-bg-inner {
		position: absolute;
		top: rem(5);
		right: rem(4);
		background: url("/assets/images/tank-icon-inner@2x.png") no-repeat 0 0;
		background-size: rem(75) rem(30);
		width: rem(65);
		height: rem(18);
	}
}

.tank-info {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: $color-dark-blue-grey;
	font-weight: 600;
	margin-left: rem(4);

	&-percentage {
		display: flex;
		flex-direction: column;
		align-items: center;
		font-family: $font-family-sub;
		font-size: $font-21;
		line-height: $font-21;

		.fa-exclamation-circle {
			font-size: $font-16;
			color: $color-red;
			position: relative;
		}

		span {
			margin-top: rem(4);
		}
	}

	&-value {
		display: flex;
		flex-flow: row wrap;
		gap: rem(4);
		font-size: $font-14;
		line-height: 1;
	}
}

.smallest {
	.tank-icon {
		flex: 0 0 rem(23);
		width: rem(23);
		height: rem(39);

		&-bar {
			top: rem(3);
			left: rem(3);
			width: rem(17);
			height: rem(33);
		}

		&-bg {
			background-size: rem(23) rem(39);
			width: rem(23);
			height: rem(39);
		}

		&-bg-inner {
			position: absolute;
			top: rem(3);
			left: rem(3);
			background: url("/assets/images/h-tank-icon.svg") no-repeat 0 0;
			background-size: rem(17) rem(33);
			width: rem(17);
			height: rem(33);
		}
	}

	.tank-info {
		margin-left: rem(4);

		&-percentage {
			font-size: $font-18;
			line-height: $font-18;

			.fa-exclamation-circle {
				width: rem(10);
				height: rem(10);
			}

			span {
				margin-top: rem(2);
			}
		}

		&-value {
			font-size: $font-12;
		}
	}
}

.large {
	.tank-icon {
		flex: 0 0 rem(52);
		width: rem(120);
		height: rem(37);

		&-bar {
			width: rem(130);
			height: rem(37);
		}

		&-value {
			height: rem(25);
			left: rem(16);
		}

		&-bg {
			background-size: rem(130) rem(37);
			width: rem(130);
			height: rem(37);
		}

		&-bg-inner {
			position: absolute;
			top: rem(0);
			left: rem(5);
			background: url("/assets/images/tank-icon-inner@2x.png") no-repeat 0 0;
			background-size: rem(130) rem(37);
			width: rem(110);
			height: rem(37);
		}
	}

	.tank-info {
		margin-left: rem(8);

		&-percentage {
			font-size: $font-48;
			line-height: $font-40;
			align-items: center;

			.fa-exclamation-circle {
				font-size: $font-20;
				top: -2px;
			}
		}

		&-value {
			font-size: $font-26;
		}
	}
}
</style>
