<template>
	<BaseDeviceWithCompany
		:deviceLink="deviceLink"
		:data="data"
		:iconSize="iconSize"
		:style="{border: haveLiquidColor}"
	>
		<template #icon>
			<BaseStandardIcon :data="data.graph" :iconSize="iconSize"/>
		</template>
		<template #detail-info>
			<div class="container-detail-info">
				<BaseTagStandardModule
					v-if="data.conditionStatus.status"
					size="small"
					:bgColor="data.conditionStatus.bgColor"
					:borderColor="data.conditionStatus.borderColor"
					:textColor="data.conditionStatus.textColor"
				>
					{{ data.conditionStatus.title }}
				</BaseTagStandardModule>
				<div class="container-detail-info-ship-id">
					<span>
						{{ getDeviceName }}
					</span>
				</div>
				<div class="container-detail-info-name">
					<span>
						{{ data.name | capitalize }}
					</span>
				</div>
				<div v-for="(item,index) in data.rows" :key="index" class="container-detail-info-detail">
					<span v-html="item.title">
					</span>
					<span v-html="item.value">
					</span>
				</div>
			</div>
		</template>
	</BaseDeviceWithCompany>
</template>

<script>
import { mapState } from "vuex";
import BaseStandardIcon from "@/components/BaseStandardIcon.vue";
import BaseTagStandardModule from "@/components/BaseTagStandardModule.vue";
import BaseDeviceWithCompany from "@/components/dashboard/modules/deviceWithCompany/BaseDeviceWithCompany.vue";

import { MODULE_DEVICE_STATUSES } from "../../../../enums/modules";

export default {
	name: "StandardModuleWithCompany",

	components: {
		BaseStandardIcon,
		BaseDeviceWithCompany,
		BaseTagStandardModule
	},

	props: {
		deviceLink: {
			type: Object,
			required: true
		},
		data: {
			type: Object,
			required: true,
			default: () => ({})
		}
	},

	computed: {
		...mapState("sidebar", {
			isSidebarOpen: "isSidebarOpen"
		}),

		haveLiquidColor() {
			return this.data?.graph?.liquid?.deviceCardBorderColor ? `1px solid ${this.data?.graph?.liquid?.deviceCardBorderColor}` : "";
		},

		iconSize() {
			let size = "smallest";
			const screenWidth = window.innerWidth
						|| document.documentElement.clientWidth
						|| document.body.clientWidth;

			if (screenWidth < 1200 || this.isSidebarOpen) {
				size = "small";
			}

			if (size === "smallest") {
				size = "small";
			} else if (size === "small") {
				size = "medium";
			}
			return size;
		},

		getDeviceName() {
			if (this.data.deviceCustomerName) {
				return this.data.deviceCustomerName;
			}

			return this.data.shipId;
		},

		status() {
			return MODULE_DEVICE_STATUSES[this.data.deliveryStatus].title;
		},

		tagClass() {
			const status = this.data.deliveryStatus;

			return status
				? MODULE_DEVICE_STATUSES[status].tagClass
				: null;
		}
	}
};
</script>

<style lang="scss" scoped>
/* stylelint-disable */
::v-deep {
	.container-detail-icon {
		width: rem(80);
		height: rem(80);
		margin-top: rem(16);
		margin-right: rem(12);
		margin-left: rem(16);

		.wrapper-icon {
			margin-top: rem(4);
			margin-left: 1px;
		}
	}
}
/* stylelint-enable */
.container-detail {
	// container-detail-info
	&-info {
		position: relative;
		flex: 1;
		margin-top: rem(8);

		.tag {
			position: absolute;
			top: rem(8);
			right: rem(4);
		}

		// container-detail-info-ship-id
		&-ship-id {
			@include fontSub();

			display: flex;
			height: rem(22);
			align-items: center;
			font-size: $font-18;
			color: $color-dark-blue-grey;
		}

		// container-detail-info-name
		&-name {
			@include fontSub();

			display: flex;
			height: rem(29);
			align-items: center;
			font-size: $font-24;
			font-weight: $font-weight-bold;
			color: $color-dark-blue-grey;
		}

		// container-detail-info-detail
		&-detail {
			margin-top: rem(4);
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: rem(14);

			font-size: $font-14;
			color: $color-dark-blue-grey;
			line-height: rem(16);
			white-space: nowrap;

			::v-deep span p {
				color: $color-dark-blue-grey;
				font-size: $font-14;
			}
		}
	}
}
</style>