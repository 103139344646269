<template>
	<div class="grafana-container">
		<iframe :src="grafanaUrl" class="grafana-dashboard" frameborder="0"></iframe>
		<div v-if="user.role === 'SUPER_ADMIN'">
			<BaseFormInput
				@input="handleInputIframe"
				:inputOptions="['small']"
				label="Iframe Grafana *"
				inline
			/>
			<button @click="add">ADD</button>
			<div v-if="listIframe.length > 0">
				<div class="iframe-container"  v-for="(item,key) in listIframe" :key="key" v-html="item">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions } from "vuex";
import { isEmpty } from "../../selectors/helpers/index";
import userPermission from "../../mixins/userPermission";

export default {
	mixins: [userPermission],
	computed: {
		grafanaUrl() {
			return process.env.VUE_APP_GRAFANA_ZLD_URL;
		}
	},

	data() {
		return {
			inputIframe: "",
			listIframe: []
		};
	},

	mounted() {
		this.addActivityLogGrafanaView({
			shopToId: this.$route.params.id,
			httpStatus: 200
		});
		this.$emit("loaded");
	},

	methods: {
		...mapActions("activityLogAdd", {
			addActivityLogGrafanaView: "addActivityLogGrafanaView"
		}),
		handleInputIframe(value) {
			this.inputIframe = value;
		},
		add() {
			if (isEmpty(this.inputIframe)) return;
			const replaceValue = this.inputIframe.replace(`width="450"`, ``).replace(`height="200"`, `style="width:100%;height:600px;"`).replace(`monitoring.bigcloudth.com`, `apis.bigcloudth.com`);
			this.listIframe = [...this.listIframe, replaceValue];
		}
	}
};
</script>

<style lang="scss" scoped>
.grafana-container {
	display: flex;
	flex-direction: column;
	gap: rem(12);

	.grafana-dashboard {
		width: 100%;
		height: rem(690);
	}
}

.iframe-container {
	padding: rem(24);
	margin-top: rem(16);
	border: 1px grey solid;
}

</style>