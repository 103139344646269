<template>
	<div>
		<form class="form mb-3" @submit.prevent="handleSubmit(standModuleView.STANDARD_UPDATE)">
			<div class="header-input">
				<div class="item">
					<div class="form-input-item">
						<div class="label">Application</div>
						<BaseDropdown
							:searchable="false"
							v-model="forms.appValue"
							:list="applicationOptions"
							:placeholder="`Please Select`"
							class="title"
							:is-error="isAppValueError"
							:error-message="getAppValueErrorMessage"
							inline
							@input="clearApplyToValues"
						/>
					</div>
				</div>
				<div class="item">
					<div class="form-input-item">
						<div class="label">Title</div>
						<BaseFormInput
							v-model="forms.title"
							:inputOptions="['small']"
							:is-error="isTitleError"
							:error-message="getTitleErrorMessage"
							class="title"
							placeholder="Template title"
							:maxLength="40"
						/>
					</div>
				</div>
				<div class="item">
					<div class="form-input-item">
						<div class="label">API URL</div>
						<BaseFormInput
							v-model="forms.apiURL"
							:inputOptions="['small']"
							:is-error="isApiURLError"
							:error-message="getApiURLErrorMessage"
							class="api-url"
							placeholder="API URL"
						/>
					</div>
				</div>
			</div>
			<div class="border-line"></div>
			<div
				:class="['row row-eq-height', { 'hide-content': false }]"
			>
				<div class="col-12 col-xl-12">
					<BaseCard rounded>
						<template v-slot:header>
							<h4>Overview</h4>
							<div class="current-time">Last update: {{ lastUpdateFormat }}</div>
						</template>
						<template v-slot:body>
							<div class="box-overview">
								<div class="side-graph">
									<BaseDropdown
										:searchable="false"
										v-model="forms.graphValue"
										:list="graphOptions"
										:placeholder="`Select graph`"
										:is-error="isGraphValueError"
										:error-message="getGraphValueErrorMessage"
										inline
									/>
									<div class="graph">
										<img v-if="forms.graph" :src="forms.graph.imagePath" />
									</div>
									<div v-if="forms.graph" class="section-add-column d-flex gap-3 flex-wrap">
										<div class="input-group max-width-400">
											<BaseInputEditor
												v-model="forms.graphUnit"
												:is-error="isGraphUnitError"
												:error-message="getGraphUnitErrorMessage"
												placeholder="Unit of graph"
											/>
										</div>
										<template v-if="forms.graph">
											<div
												class="input-group"
												v-for="(v, index) in $v.forms.graphApiTagNames.$each.$iter"
												:key="index"
											>
												<BaseDropdown
													v-model="v.name.$model"
													:list="apiTagNameOptions"
													:label="v.label.$model"
													:is-error="checkIfValid(v.name)"
													error-message="Required field"
													:placeholder="`Please select API tag name`"
												/>
											</div>
										</template>
										<div v-if="LIST_SHOW_MIN_MAX_GRAPH.includes(forms.graphValue)" class="d-flex gap-2 min-max">
											<BaseFormInputStdModule
												class="min-max-input"
												inline
												label="Min"
												:min="-9999"
												type="number"
												step=".001"
												v-model="forms.graphMin"
												:is-error="isGraphMinError"
												:error-message="getGraphMinErrorMessage"
											/>
											<BaseFormInputStdModule
												class="min-max-input"
												inline
												label="Max"
												:min="-9999"
												type="number"
												step=".001"
												v-model="forms.graphMax"
												:is-error="isGraphMaxError"
												:error-message="getGraphMaxErrorMessage"
											/>
										</div>
									</div>
								</div>
								<div class="side-overview">
									<div class="graph-status">
										<label>Status</label>
										<BaseSwitch
											class="switch-status"
											v-model="$v.forms.status.$model"
											:checked="$v.forms.status.$model"
										/>
									</div>
									<div class="border-line"></div>
									<draggable :list="forms.overviews" @change="checkMove" :options="{handle:'.item-draggable'}">
										<div
											v-for="(overview, index) in forms.overviews"
											:key="overview.uuid"
										>
											<div class="d-flex flex-wrap gap-2">
												<div class="col-12 font-weight-bold" v-if="overview.type === overviewType.SUBTITLE">Subtitle</div>
												<div class="col-12 font-weight-bold" v-else-if="overview.type === overviewType.TITLE_COLOR_BAR">Title + Color bar</div>
												<div class="col-12 font-weight-bold" v-else-if="overview.type === overviewType.TITLE_PROGRESS_BAR">Title + Percentage progress bar</div>
												<div class="col-12 d-flex align-item-center detail-content">
													<div class="d-flex col-7 detail-content-left">
														<BaseInputEditor
															v-model="$v.forms.overviews[index].title.$model"
															:is-error="checkIfValid($v.forms.overviews[index].title)"
															error-message="Required field"
															placeholder="Title"
														/>
													</div>
													<div class="d-flex align-item-center content-right col-5">
														<div class="min-max-input">
															<BaseFormInputStdModule
																v-if="overview.type !== overviewType.TITLE_PROGRESS_BAR && overview.type !== overviewType.SUBTITLE && overview.type !== overviewType.TITLE_COLOR_BAR"
																v-model="$v.forms.overviews[index].min.$model"
																:is-error="checkIfValid($v.forms.overviews[index].min)"
																error-message="Required field"
																label="Min"
																step=".001"
																:min="-9999"
																inline
																type="number"
															/>
														</div>
														<div class="min-max-input">
															<BaseFormInputStdModule
																v-if="overview.type !== overviewType.TITLE_PROGRESS_BAR && overview.type !== overviewType.SUBTITLE && overview.type !== overviewType.TITLE_COLOR_BAR"
																v-model="$v.forms.overviews[index].max.$model"
																:is-error="checkIfValid($v.forms.overviews[index].max)"
																error-message="Required field"
																label="Max"
																step=".001"
																:min="-9999"
																inline
																type="number"
															/>
														</div>
														<div class="item-draggable">
															<FontAwesomeIcon class="clickable icon" :icon="['far', 'bars']" />
														</div>
														<div class="trash">
															<FontAwesomeIcon
																class="clickable icon"
																:icon="['far', 'trash']"
																@click="showModalDeleteColumnOverview(forms.overviews[index].title, index)"
															/>
														</div>
														<div class="status">
															<BaseSwitch
																class="switch-status"
																v-model="$v.forms.overviews[index].status.$model"
																:checked="$v.forms.overviews[index].status.$model"
															/>
														</div>
													</div>
												</div>
												<div class="col-12 d-flex detail-content" v-if="overview.type !== overviewType.SUBTITLE">
													<div class="d-flex align-item-center col-7 detail-content-left">
														<div class="oval"></div>
														<BaseDropdown
															v-model="$v.forms.overviews[index].apiTagName.$model"
															:list="apiTagNameOptions"
															:is-error="checkIfValid($v.forms.overviews[index].apiTagName)"
															:placeholder="`Please select API tag name`"
															class="ml-2"
															error-message="Required field"
														/>
													</div>
													<div class="d-flex align-item-center content-right col-5 width-400">
													</div>
												</div>
											</div>
											<div class="border-line"></div>
										</div>
									</draggable>
									<div class="add-new-button">
										<BaseButton
											size="medium"
											type="primary"
											rounded
											outline
											htmlType="button"
											@click="handleOpenOverviewTypeModal"
										>
											Add new column
										</BaseButton>
									</div>
								</div>
							</div>
						</template>
					</BaseCard>
				</div>
			</div>
			<BaseCard rounded>
				<template v-slot:header>
					<h4>History & Analysis</h4>
				</template>
				<template v-slot:body>
					<div class="history-analysis">
						<div class="manage-tab">
							<h4>History</h4>
							<BaseSwitch
								class="switch-status"
								v-model="$v.forms.historyStatus.$model"
								:checked="$v.forms.historyStatus.$model"
							/>
						</div>
						<div class="border-line">
						</div>
						<div class="form-input-item mb-2">
							<BaseFormInput
								v-model="forms.historyApiURL"
								:inputOptions="['small']"
								:is-error="isHistoryApiURLError"
								:error-message="getHistoryApiURLErrorMessage"
								class="api-url"
								label="API URL"
								inline
							/>
						</div>
						<h4>Export SQL</h4>
						<div class="select-column mb-2">
							<div class="d-flex title">
								<div class="title-name">Select columns</div>
							</div>
							<div class="d-flex select-column-table">
								<div v-for="(select, index) in forms.selectColumns"
									:key="index"
									class="d-flex select-column-row mb-3">
									<div class="column">
										<BaseFormInput
											v-model="$v.forms.selectColumns[index].select.$model"
											:inputOptions="['small']"
											:is-error="checkIfValid($v.forms.selectColumns[index].select)"
											error-message="Required Field"
											class="api-url"
										/>
									</div>
									<div class="excel-header">
										<div class="header">Excel header</div>
										<BaseInputEditor
											v-model="$v.forms.selectColumns[index].as.$model"
											:is-error="checkIfValid($v.forms.selectColumns[index].as)"
											error-message="Required Field"
											class="api-url"
										/>
									</div>
									<div class="trash">
										<FontAwesomeIcon v-if="index !== 0"
											class="clickable icon"
											:icon="['far', 'trash']"
											@click="showModalDeleteColumn(index)"
										/>
									</div>
								</div>
								<div class="add-new-button">
									<BaseButton
										size="medium"
										type="primary"
										rounded
										outline
										htmlType="button"
										@click="handleAddSelectColumns"
									>
										Add new column
									</BaseButton>
								</div>
							</div>
						</div>
						<div class="form-input-item mb-2 from">
							<BaseFormInput
								v-model="forms.from"
								:inputOptions="['small']"
								:is-error="checkIfValid($v.forms.from)"
								error-message="Required Field"
								label="From"
								inline
							/>
						</div>
						<div class="content-row mb-2">
							<div class="title">Where</div>
							<div class="content" v-html="`{{PERIOD_FROM_DATE}} to {{PERIOD_TO_DATE}} AND {{SHIP_ID}}`"></div>
						</div>
						<div class="content-row mb-2">
							<div class="title">And</div>
							<BaseTextArea v-model="$v.forms.whereCondition.$model" />
						</div>
						<div class="content-row mb-2">
							<div class="title">Order By</div>
							<div class="content">dtRecordChar ASC</div>
						</div>
						<div class="border-line">
						</div>
						<div class="manage-tab">
							<h4>Analysis</h4>
							<BaseSwitch
								class="switch-status"
								v-model="$v.forms.analysisStatus.$model"
								:checked="$v.forms.analysisStatus.$model"
							/>
						</div>
					</div>
				</template>
			</BaseCard>
			<ApplyToDevice
				:applyToData="dataApplyTo"
				:soldToValues="forms.soldToValues"
				:shipToValues="forms.shipToValues"
				:deviceValues="forms.deviceValues"
				:isDeviceError="isDeviceError"
				:deviceErrorMessage="getDeviceDuplicateErrorMessage"
				@handleChangeSoldTo="handleChangeSoldTo"
				@handleChangeShipTo="handleChangeShipTo"
				@handleChangeDevice="handleChangeDevice"
				module="TANK"
			/>
			<div class="box-action-btn">
				<BaseButton
					size="large"
					type="primary"
					htmlType="button"
					outline
					rounded
					@click="handleCancle"
				>
					Cancel
				</BaseButton>
				<BaseButton
					v-if="type === standModuleView.STANDARD_CREATE"
					size="large"
					type="primary"
					rounded
				>
					Create new template
				</BaseButton>
				<BaseButton
					v-else-if="type === standModuleView.STANDARD_UPDATE"
					size="large"
					type="primary"
					rounded
				>
					Save changes
				</BaseButton>
			</div>
			<div v-if="type === standModuleView.STANDARD_UPDATE" class="border-line">
			</div>
			<div class="box-action-btn">
				<BaseButton
					v-if="type === standModuleView.STANDARD_UPDATE"
					size="large"
					htmlType="button"
					type="primary"
					rounded
					@click="handleOpenSaveAsNewTemplate"

				>
					Save as new template
				</BaseButton>
			</div>
		</form>
		<ModalSelectOverviewType
			@submit="handleAddOverviewType"
		/>
		<ModalSaveAsNewTemplate
			@submit="handleSaveAsNewTemplate"
		/>
		<BaseModalConfirmDelete
			:modal-name="MODAL_DELETE_COLUMN"
			title="Delete Column"
			:message="modalMessageDeleteColumn"
			submitButtonText="Yes, delete"
			@onCancel="handleModalCancelDelete"
			@onConfirm="handleDeleteSelectColumns"
		/>
		<BaseModalConfirmDelete
			:modal-name="MODAL_DELETE_COLUMN_OVERVIEW"
			title="Delete Column"
			:message="modalMessageDeleteColumnOverview"
			submitButtonText="Yes, delete"
			@onCancel="handleModalCancelDeleteOverview"
			@onConfirm="handleDeleteOverview"
		/>
	</div>
</template>
<script>
import { mapState } from "vuex";
import { v4 as uuidv4 } from "uuid";
import draggable from "vuedraggable";
import { validationMixin } from "vuelidate";
import dayjs from "dayjs";
import { required } from "vuelidate/lib/validators";
import ModalSelectOverviewType from "@/components/StandardModule/modal/ModalSelectOverviewType.vue";
import ModalSaveAsNewTemplate from "@/components/StandardModule/modal/ModalSaveAsNewTemplate.vue";
import BaseSwitch from "@/components/BaseSwitch.vue";
import ApplyToDevice from "@/components/StandardModule/ApplyToDevice.vue";
import BaseCard from "@/components/BaseCard.vue";
import BaseInputEditor from "@/components/BaseInputEditor.vue";
import BaseFormInputStdModule from "@/components/BaseFormInputStdModule.vue";
import BaseTextArea from "@/components/BaseTextArea.vue";
import BaseModalConfirmDelete from "@/components/BaseModalConfirmDelete.vue";
import { checkDuplicateTitleAPI, getLayoutAddablesStdModule } from "@/services/api/standardModule.api";
import { getLayoutAddables } from "../../services/api/layoutCustomisation.api";
import { checkIfValid, getvalidateMessage } from "../../selectors/formValidate/validateHelper";
import { overviewType, standModuleView } from "../../enums/standardModule";
import { trasnformSoldToOptions } from "../../selectors/transform/devicePermission";
import { PAGE_NAME } from "../../enums/pagePermission";


// TODO: need to refactor when we have a time [BIG-3411]
// best solution is new column name -> isShowMinMax in table sm_indicator icon and send passing with API /standard-module/indicator-icons and change seed file
const LIST_SHOW_MIN_MAX_GRAPH = [
	1, // air comp icon
	2, // electricity icon
	12, // std good bad icon
	13, // insert for emergency (now in db id 13 not have data)
	14, // std bad good icon
	15 // bad good bad icon
];

export default {
	components: {
		BaseCard,
		ModalSelectOverviewType,
		ModalSaveAsNewTemplate,
		BaseSwitch,
		ApplyToDevice,
		draggable,
		BaseInputEditor,
		BaseTextArea,
		BaseModalConfirmDelete,
		BaseFormInputStdModule
	},

	props: {
		type: {
			type: String,
			default: standModuleView.STANDARD_CREATE
		},
		editData: {
			type: Object,
			default: () => ({
				graphValue: null,
				appValue: null,
				graph: null,
				graphUnit: "",
				graphApiTagNames: [null, null, null, null],
				graphMin: null,
				graphMax: null,
				status: true,
				title: "",
				apiURL: "",
				overviews: [],
				soldToValues: [],
				shipToValues: [],
				deviceValues: [],
				duplicateDevice: [],
				selectColumns: [
					{ select: "", as: "" }
				],
				from: "",
				whereCondition: "",
				historyApiURL: "",
				historyStatus: true,
				analysisStatus: true
			})
		}
	},

	data() {
		return {
			PAGE_NAME,
			LIST_SHOW_MIN_MAX_GRAPH,
			msgDelOverview: "",
			checkIfValid,
			overviewType,
			standModuleView,
			ignoreFirstTimeEdit: this.type === standModuleView.STANDARD_CREATE ? 1 : 0,
			forms: {
				graphValue: null,
				appValue: null,
				graph: null,
				graphUnit: "",
				graphApiTagNames: [null, null, null, null],
				graphMin: null,
				graphMax: null,
				status: true,
				title: "",
				apiURL: "",
				overviews: [],
				soldToValues: [],
				shipToValues: [],
				deviceValues: [],
				duplicateDevice: [],
				selectColumns: [
					{ select: "", as: "" }
				],
				from: "",
				whereCondition: "",
				historyApiURL: "",
				historyStatus: true,
				analysisStatus: true
			},
			dataApplyTo: {
				soldTos: [],
				shipTos: [],
				devices: []
			},
			gaugeBarValue: "bgb",
			overviewTypeOptions: [
				{
					label: "Title",
					value: overviewType.TITLE
				},
				{
					label: "Title + Color bar",
					value: overviewType.TITLE_COLOR_BAR
				},
				{
					label: "Title + % Progress bar",
					value: overviewType.TITLE_PROGRESS_BAR
				},
				{
					label: "Subtitle",
					value: overviewType.SUBTITLE
				}
			],
			isErrorDevice: false,
			MODAL_DELETE_COLUMN: "model-delete-column",
			MODAL_DELETE_COLUMN_OVERVIEW: "model-delete-column-overview"
		};
	},

	watch: {
		editData: {
			handler(value) {
				if (this.type === standModuleView.STANDARD_UPDATE && this.$route.params.id) {
					this.forms = value;
					this.parseAppliedTo(value);
				}
			}
		},
		"forms.graphValue": {
			handler(value) {
				this.forms.graph = this.graphOptions.find((option) => option.value === value);

				// ignoreThis line when First EditData
				if (this.ignoreFirstTimeEdit !== 0) {
					this.forms.graphApiTagNames = this.forms.graph?.apiTags.map((apiTag) => {
						return {
							label: apiTag,
							name: ""
						};
					});
				}
				this.ignoreFirstTimeEdit += 1;
			}
		},
		"forms.appValue": {
			async handler(value) {
				let responseApplyTo;
				if (value !== null) {
					if (this.type === standModuleView.STANDARD_CREATE) {
						responseApplyTo = await getLayoutAddablesStdModule({ app: value });
					} else {
						responseApplyTo = await getLayoutAddables({ app: value });
					}
					this.dataApplyTo = responseApplyTo.data;
				}
			}
		}
	},

	mixins: [
		validationMixin
	],

	validations() {
		const dynamicOverviewRule = {};
		const dynamicSelectColumnsRule = {};
		this.forms.overviews.forEach((overview, index) => {
			if (overview.type === overviewType.TITLE_COLOR_BAR) {
				dynamicOverviewRule[index] = {
					type: {},
					min: {},
					max: {},
					title: { required },
					apiTagName: {},
					status: {}
				};
			} else if (overview.type === overviewType.TITLE_PROGRESS_BAR) {
				dynamicOverviewRule[index] = {
					type: {},
					title: { required },
					apiTagName: {},
					status: {}
				};
			} else {
				dynamicOverviewRule[index] = {
					type: {},
					min: {},
					max: {},
					title: { required },
					apiTagName: {},
					status: {}
				};
			}
		});

		this.forms.selectColumns.forEach((overview, index) => {
			dynamicSelectColumnsRule[index] = {
				select: { },
				as: { }
			};
		});

		const formsValidation = {
			forms: {
				appValue: {
					required
				},
				graphValue: {
					required
				},
				graphUnit: {
					required
				},
				graphMin: {},
				graphMax: {},
				graphApiTagNames: {
					$each: {
						name: {
						},
						label: {}
					}
				},
				status: {
				},
				title: {
					required,
					async duplicate(value) {
						try {
							if (value && value !== "") {
								this.$v.forms.title.$touch();
								const params = {};
								params.title = value;

								if (this.type === standModuleView.STANDARD_UPDATE) {
									params.id = this.$route.params.id || null;
								}
								const { data } = await checkDuplicateTitleAPI(params);
								return !data.titleDuplicate;
							}
						} catch {
							return true;
						}
						return true;
					}
				},
				apiURL: {
					required
				},
				overviews: dynamicOverviewRule,
				selectColumns: dynamicSelectColumnsRule,
				from: { },
				whereCondition: { },
				historyApiURL: { },
				historyStatus: {},
				analysisStatus: {}
			}
		};

		return formsValidation;
	},

	computed: {
		...mapState("standardModuleCreate", {
			graphOptions: "graphOptions",
			apiTagNameOptions: "apiTagNameOptions",
			applicationOptions: "applicationOptions"
		}),

		modalMessageDeleteColumn() {
			return `<div>
				<div>Are you sure to delete this column? </div>
			</div>`;
		},

		modalMessageDeleteColumnOverview() {
			return `<div>
				<div>Are you sure to delete column <span class="show-title-delete">${this.msgDelOverview}</span>? </div>
			</div>`;
		},

		isDevicesDuplicate() {
			return this.duplicateDevice.length > 0;
		},

		isDeviceError() {
			return this.isErrorDevice;
		},

		lastUpdateFormat() {
			if (this.type === standModuleView.STANDARD_UPDATE) {
				return this.forms.lastUpdate ?? "-";
			}
			return dayjs(new Date()).format("D MMM YYYY, HH:mm") ?? "-";
		},

		getDeviceDuplicateErrorMessage() {
			if (this.isErrorDevice) {
				return "Please select Apply to Device";
			}
			let word = "";
			for (let i = 0; i < this.forms.duplicateDevice.length; i++) {
				if (i === this.duplicateDevice.length - 1) {
					word += `${this.forms.duplicateDevice[i].key}`;
				} else {
					word += `${this.forms.duplicateDevice[i].key}, `;
				}
			}
			if (this.forms.duplicateDevice.length > 0) {
				return `${word} already assigned to another layout, please remove the device to continue`;
			}
			return "";
		},

		isTitleError() {
			const field = this.$v.forms.title;
			return checkIfValid(field);
		},

		getTitleErrorMessage() {
			const field = this.$v.forms.title;
			return getvalidateMessage(field, {
				requiredMessage: "Required field",
				duplicateMessage: "This title is currently in use."
			});
		},

		isApiURLError() {
			const field = this.$v.forms.apiURL;
			return checkIfValid(field);
		},

		isHistoryApiURLError() {
			const field = this.$v.forms.historyApiURL;
			return checkIfValid(field);
		},

		getHistoryApiURLErrorMessage() {
			const field = this.$v.forms.historyApiURL;
			return getvalidateMessage(field, {
				requiredMessage: "Required field"
			});
		},

		getApiURLErrorMessage() {
			const field = this.$v.forms.apiURL;
			return getvalidateMessage(field, {
				requiredMessage: "Required field"
			});
		},

		isAppValueError() {
			const field = this.$v.forms.appValue;
			return checkIfValid(field);
		},

		getAppValueErrorMessage() {
			const field = this.$v.forms.appValue;
			return getvalidateMessage(field, {
				requiredMessage: "Required field"
			});
		},

		isGraphValueError() {
			const field = this.$v.forms.graphValue;
			return checkIfValid(field);
		},

		getGraphValueErrorMessage() {
			const field = this.$v.forms.graphValue;
			return getvalidateMessage(field, {
				requiredMessage: "Required field"
			});
		},

		isGraphUnitError() {
			const field = this.$v.forms.graphUnit;
			return checkIfValid(field);
		},

		getGraphUnitErrorMessage() {
			const field = this.$v.forms.graphUnit;
			return getvalidateMessage(field, {
				requiredMessage: "Required field"
			});
		},

		isGraphMinError() {
			const field = this.$v.forms.graphMin;
			return checkIfValid(field);
		},

		getGraphMinErrorMessage() {
			const field = this.$v.forms.graphMin;
			return getvalidateMessage(field, {
				requiredMessage: "Required field"
			});
		},

		isGraphMaxError() {
			const field = this.$v.forms.graphMax;
			return checkIfValid(field);
		},

		getGraphMaxErrorMessage() {
			const field = this.$v.forms.graphMax;
			return getvalidateMessage(field, {
				requiredMessage: "Required field"
			});
		}
	},
	methods: {
		clearApplyToValues() {
			this.forms.soldToValues = [];
			this.forms.shipToValues = [];
			this.forms.deviceValues = [];
		},

		handleCancle() {
			this.$router.push({
				name: PAGE_NAME.STANDARD_MODULE_LIST
			});
		},

		parseAppliedTo({ soldToValues, shipToValues, deviceValues }) {
			const defaultSoldTos = trasnformSoldToOptions(soldToValues);
			const defaultShipTos = shipToValues;
			const defaultDevices = deviceValues.reduce((acc, { id, productId, shipId, app, shipToId, ...props }) => {
				const newValue = {
					id,
					code: id,
					name: `${productId} - ${shipId}`,
					key: `${productId} - ${shipId}`,
					shipToId,
					app,
					...props
				};
				return [
					...acc,
					newValue
				];
			}, []);
			this.forms.soldToValues = defaultSoldTos;
			this.forms.shipToValues = defaultShipTos;
			this.forms.deviceValues = defaultDevices;
		},

		async handleSubmit(type, titleAsNewTemplate = "") {
			this.$v.forms.$touch();
			if (!this.$v.$invalid) {
				// type is Create or Edit
				const payload = {
					type,
					forms: this.forms,
					titleAsNewTemplate
				};
				this.$emit("submit", payload);
			}
		},

		showModalDeleteColumn(key) {
			this.$modal.show(this.MODAL_DELETE_COLUMN, key);
		},

		showModalDeleteColumnOverview(title, key) {
			// remove tag p on textInputEditor for show in modal
			this.msgDelOverview = title.replace(/<p>|<\/p>/g, "").replace(/<p>|<\/p>/g, "");
			this.$modal.show(this.MODAL_DELETE_COLUMN_OVERVIEW, key);
		},

		handleModalCancelDelete() {
			this.$modal.hide(this.MODAL_DELETE_COLUMN);
		},

		handleModalCancelDeleteOverview() {
			this.$modal.hide(this.MODAL_DELETE_COLUMN_OVERVIEW);
		},

		checkMove() {
			this.$v.$reset();
		},

		handleAddOverviewType(selectedOverviewType) {
			this.forms.overviews.push({
				id: null,
				type: selectedOverviewType,
				title: "",
				apiTagName: "",
				min: null,
				max: null,
				status: true,
				colorBarType: null,
				uuid: uuidv4()
			});
		},

		handleSaveAsNewTemplate(title) {
			this.handleSubmit(standModuleView.STANDARD_CREATE, title);
		},

		handleAddSelectColumns() {
			this.forms.selectColumns.push({
				select: "",
				as: ""
			});
		},

		handleOpenOverviewTypeModal() {
			this.$modal.show("modal-select-overview-type", {
				overviewTypeOptions: this.overviewTypeOptions
			});
		},

		handleOpenSaveAsNewTemplate() {
			this.$modal.show("modal-save-as-new-template", {});
		},

		handleDeleteOverview(deleteIndex) {
			this.forms.overviews = this.forms.overviews.filter((overview, index) => {
				return index !== deleteIndex;
			});
			this.$modal.hide(this.MODAL_DELETE_COLUMN_OVERVIEW);
		},

		handleDeleteSelectColumns(deleteIndex) {
			this.forms.selectColumns = this.forms.selectColumns.filter((overview, index) => {
				return index !== deleteIndex;
			});
			this.$modal.hide(this.MODAL_DELETE_COLUMN);
		},

		handleChangeEditor(value) {
			this.mockValue = value;
		},

		handleChangeSoldTo(value) {
			this.forms.soldToValues = value;
		},

		handleChangeShipTo(value) {
			this.forms.shipToValues = value;
		},

		handleChangeDevice(value) {
			this.forms.deviceValues = value;
			this.forms.duplicateDevice = [];
		}
	}
};
</script>

<style lang="scss" scoped>
.header-input {
	display: flex;
	flex-flow: row wrap;

	.item {
		margin-bottom: $spacing-6;
		display: flex;

		.label {
			display: flex;
			flex-grow: 1;
			align-items: center;
			color: $color-dark-blue-grey;
			font-weight: $font-weight-bold;
		}

		.form-input-item {
			.title {
				width: rem(300);
				margin-right: rem(24);
			}

			.api-url {
				width: rem(300);
			}
		}
	}
}

::v-deep {
	.control.max-length {
		margin-bottom: 0;
	}

	.wrapper-multi-select {
		.error-message.has-label {
			margin-left: rem(130);
		}
	}

	.error-message.has-label {
		margin-left: rem(30);
	}
}

.border-line {
	height: 1px;
	width: 100%;
	margin: rem(24) 0 rem(24) 0;
	background-color: $color-silver;
}

.box-oval {
	position: absolute;
	left: rem(8);
	display: flex;
	align-items: center;
	height: 100%;
}

.oval {
	width: rem(8);
	height: rem(8);
	border-radius: rem(8);
	background-color: $color-silver;
}

.box-overview {
	display: flex;
	flex-flow: row nowrap;
	gap: rem(24);

	@include mobile {
		flex-wrap: wrap;
	}

	.side-graph {
		width: 25%;

		@include mobile {
			width: 100%;

			.max-width-400 {
				max-width: rem(650);
			}
		}

		.graph {
			margin-top: rem(24);
			margin-bottom: rem(24);
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.section-add-column {
			.input-group {
				width: 100%;
			}

			.min-max {
				flex-flow: row wrap;
			}

			.min-max-input {
				width: rem(100);

				::v-deep {
					.container-icon-right {
						padding-right: 4px;
					}

					.error-message.has-label {
						margin-left: rem(30);
					}
				}
			}
		}
	}

	.side-overview {
		width: 75%;

		@include mobile {
			width: 100%;
		}

		.icon {
			color: $color-dark-blue-grey;
		}

		label {
			font-weight: $font-weight-bold;
			margin-right: rem(16);
		}

		.graph-status {
			display: flex;
			height: rem(40);
			justify-content: flex-end;
			align-items: center;
		}
	}

	::v-deep {
		.in-line {
			.input-label {
				min-width: unset;
				margin-right: rem(8);
			}
		}
	}

	.wrapper-api-tag-name {
		width: 100%;
	}

	.font-weight-bold {
		font-weight: $font-weight-bold;
	}

	.detail-content {
		@media (max-width: rem(1292)) {
			flex-wrap: wrap;

			.detail-content-left {
				width: 100%;
			}
		}

		.detail-content-left {
			max-width: rem(900);
		}

		.content-right {
			width: 100%;
			max-width: rem(400);
			justify-content: space-evenly;

			@media (max-width: rem(1292)) {
				margin-top: rem(16);
			}

			@include mobile {
				flex-wrap: wrap;
				margin-top: rem(16);
			}
		}

		.input-editor {
			flex: 1 1 rem(300);
		}

		.min-max-input {
			width: rem(100);

			::v-deep {
				.container-icon-right {
					padding-right: 4px;
				}

				.error-message.has-label {
					margin-left: rem(30);
				}
			}
		}
	}
}

.current-time {
	color: $color-dark-blue-grey;
	font-style: italic;
}

.history-analysis {
	.manage-tab {
		display: flex;
		justify-content: space-between;
	}

	.from {
		width: calc(50% + rem(42));
	}

	.content-row {
		display: flex;
		flex-direction: row;

		.title {
			display: flex;
			flex: 0 0 rem(130);
		}

		.content {
			display: flex;
			flex: 1;
		}
	}

	.border-line {
		height: 1px;
		width: 100%;
		margin: rem(16) 0 rem(16) 0;
		background-color: $color-silver;
	}

	::v-deep {
		.input-label {
			font-weight: $font-weight-regular;
		}

		.error-message.has-label {
			margin-left: rem(130);
		}
	}

	.select-column {
		display: flex;
		flex-direction: row;
		width: 100%;

		// select-column-table
		&-table {
			display: flex;
			flex-direction: column;
			width: 100%;
		}

		// select-column-row
		&-row {
			width: 100%;
		}

		.title {
			align-items: flex-start;
			flex: 1 0 rem(130);

			// title-name
			&-name {
				margin-top: rem(6);
			}
		}

		.column {
			display: flex;
			flex: 1;
		}

		.excel-header {
			display: flex;
			flex-direction: row;
			flex: 1;

			.header {
				display: flex;
				align-items: center;
				width: rem(100);
				margin: 0 rem(8) 0 rem(16);
			}
		}

		.trash {
			display: flex;
			justify-content: center;
			align-items: center;
			flex: 0 0 rem(30);
			margin-left: rem(16);
		}
	}
}

.add-new-button {
	display: flex;
	justify-content: flex-end;
}

.box-action-btn {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	gap: rem(16);
}

.max-width-600 {
	width: 100%;
	max-width: rem(600);
}

.max-width-400 {
	width: 100%;
	max-width: rem(400);
}

.width-400 {
	width: rem(400);
}
</style>