import dayjs from "dayjs";
import { transformShipTo } from "./transformShipTo";
import { transformDevices } from "./transformDevices";
import { transformQuantityRestriction } from "./transformQuantityRestriction";
import { formatCounter } from "../../helpers/formatCounter";
import { mapUnit } from "../../helpers/mapUnit";
import { DISPLAY_TIME } from "../../../enums/eOrdering/timeType";

export const transformInfo = (item, config) => {
	const device = transformDevices(item?.device) ?? null;
	const shipTo = transformShipTo(device?.shipTo)?.name ?? "-";
	const deliveryDate = dayjs(item?.deliveryDate).format("DD MMM YYYY") ?? "-";
	const deliveryTime = dayjs(item?.deliveryDate).format("HH:mm") ?? "-";
	const createdDate = dayjs(item?.createDate).format("DD/MM/YY, HH:mm") ?? "-";
	const product = device?.name ?? "-";
	const dateEdit = dayjs(item?.deliveryDate).format("YYYY-MM-DD HH:mm") ?? null;
	const maximumVolume = transformQuantityRestriction(item?.quantityRestriction)?.optimum ?? 0;
	const minimumVolume = transformQuantityRestriction(item?.quantityRestriction)?.minimum ?? 0;
	const volume = item?.orderAmount ?? "-";
	const selectedVolume = maximumVolume === volume ? "F" : "D";
	const quantity = formatCounter(item?.orderAmount) ?? "-";
	const productId = device?.productId ?? null;
	const unit = mapUnit(device, config);
	const tankNo = item?.shipId || "-";
	const poNo = item?.orderPONo || "-";
	const note = item?.orderRemark || "-";
	const createBy = item?.createBy || "-";

	const overviewGroups = [
		{
			title: "Product information",
			overviews: [
				{ title: "Order create by", value: createBy },
				{ title: "Product", value: product },
				{ title: "Ship to", value: shipTo },
				{ title: "Tank no.", value: tankNo }
			]
		},
		{
			title: "Schedule information",
			overviews: [
				{ title: "Quantity", value: `${quantity} ${unit}` },
				{ title: "Delivery date/time", value: `${deliveryDate}, ${DISPLAY_TIME[deliveryTime] || deliveryTime}` },
				{ title: "PO No.", value: poNo },
				{ title: "Note", value: note }
			]
		}
	];

	return {
		type: "EOrdering",
		orderNo: item?.orderNo ?? "-",
		volume,
		status: item?.orderStatus ?? "-",
		isEditable: item?.isEditable ?? false,
		note: item?.orderRemark ?? "-",
		poNo: item?.orderPONo ?? "-",
		tankNo,
		product: item?.productName ?? "-",
		isUrgent: item?.isUrgent ?? false,
		actualAmount: item?.actualAmount ?? "N/A",
		deliveryDate,
		createdDate,
		device,
		shipTo,
		maximumVolume,
		minimumVolume,
		selectedVolume,
		overviewGroups,
		dateEdit,
		productId
	};
};