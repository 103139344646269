<template>
	<div v-if="getGrafanaDisplay(tab).length > 0">
		<BaseCard class="chart" v-for="(item,key) in getGrafanaDisplay(tab)" :key="key">
			<template v-slot:header>
				<h4>
					<span class="title-iframe" :title="item.title">{{item.title}}</span>
				</h4>
				<div class="detail-card-header" >
				</div>
			</template>
			<template v-slot:body>
				<div class="iframe-container">
					<div class="iframe" v-html="item.url"></div>
				</div>
			</template>
		</BaseCard>
	</div>
	<BaseNoData
		v-else-if="showBaseNoData"
		class="empty"
		description="BIG portal will start showing the grafana right after we have information"
	/>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import BaseCard from "@/components/BaseCard.vue";
import BaseNoData from "@/components/BaseNoData.vue";

export default {
	name: "GrafanaManagement",

	components: {
		BaseCard,
		BaseNoData
	},

	props: {
		deviceId: {
			type: [String, Number],
			default: null
		},
		tab: {
			type: String,
			default: "Overview"
		},
		showNoData: {
			type: Boolean,
			default: true
		}
	},

	data() {
		return {
			templateId: this.$route.params.templateId ?? null
		};
	},

	async created() {
		if (this.deviceId !== null) {
			await this.getGrafanaDashboardByDeviceId(this.deviceId);
		}
	},

	computed: {
		...mapGetters("grafanaManagement", {
			getGrafanaDisplay: "getGrafanaDisplay"
		}),
		...mapState(["grafanaManagement"]),

		showBaseNoData() {
			return this.getGrafanaDisplay(this.tab).length === 0 && this.templateId && this.showNoData;
		}
	},

	methods: {
		...mapActions("grafanaManagement", {
			getGrafanaDashboardByDeviceId: "getGrafanaDashboardByDeviceId"
		})
	}
};
</script>

<style lang="scss" scoped>
.iframe-container {
	padding: 0 rem(24);
}

.iframe {
	padding: rem(24);
	border: 1px solid $color-grey-3;
}

.detail-card-header {
	display: flex;
	align-items: center;

	& > * {
		margin-left: rem(24);
	}

	span {
		display: flex;
		color: $color-grey-4;
		cursor: pointer;
	}
}

.title-iframe {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.chart {
	h4 {
		width: 70%;
	}
}

.empty {
	margin-top: rem(48);
}
</style>
