<template>
	<div
		:class="['container-footer', type, mode]"
		v-click-outside="onClickOutSideDropdown"
	>
		<ul class="container-footer-list">
			<li class="container-footer-item">
				<a class="link-menu" @click="handleOpenAboutUs">
					<span class="text">About us</span>
					<span class="icon"><FontAwesomeIcon :icon="['far', 'info-circle']" /></span>
					<div :class="['dropdown-list', type, { active: openAboutUsMenu }]">
						<div
							class="dropdown-list-item"
							@click="$emit('openModal',modalCompanyOverView)"
						>
							Company Overview
						</div>
						<div
							class="dropdown-list-item"
							@click="$emit('openModal',modalBussinessPhilosophy)"
						>
							Business Philosophy
						</div>
					</div>
				</a>
			</li>
			<li class="container-footer-item">
				<a class="link-menu" @click="$emit('openModal',modalContactUs)">
					<span class="text">Contact</span>
					<span class="icon"><FontAwesomeIcon :icon="['far', 'phone-alt']" /></span>
				</a>
			</li>
		</ul>
	</div>
</template>

<script>
import { MODAL_NAME } from "../enums/modal";

const { MODAL_COMPANY_OVERVIEW, MODAL_BUSINESS_PHILOSOPHY, MODAL_CONTACT_US } = MODAL_NAME;

export default {
	name: "BaseContact",
	data() {
		return {
			openAboutUsMenu: false
		};
	},
	props: {
		modalCompanyOverView: {
			type: String,
			default: MODAL_COMPANY_OVERVIEW
		},
		modalBussinessPhilosophy: {
			type: String,
			default: MODAL_BUSINESS_PHILOSOPHY
		},
		modalContactUs: {
			type: String,
			default: MODAL_CONTACT_US
		},
		mode: {
			type: String,
			default: ""
		},
		type: {
			type: String,
			validator: (value) => {
				return [
					"text",
					"icon"
				].includes(value);
			}
		}
	},

	methods: {
		onClickOutSideDropdown() {
			this.openAboutUsMenu = false;
		},
		handleOpenAboutUs() {
			this.openAboutUsMenu = !this.openAboutUsMenu;
		}
	}
};
</script>

<style lang="scss" scoped>
.dropdown-list {
	position: absolute;
	top: rem(-46);
	left: rem(60);
	width: rem(136);
	padding: rem(8) 0;
	background-color: $color-white;
	border-radius: 4px;
	box-shadow: 0 rem(2) rem(8) 0 rgba(16, 51, 50, 0.2);
	z-index: 4;
	display: none;

	&.icon {
		top: rem(-52);
		left: rem(30);
	}

	&.active {
		display: block;
	}

	&-item {
		padding: 0 rem(8);
		height: rem(28);
		cursor: pointer;

		&:hover {
			background-color: $color-ice-two;
			color: $color-emerald;
		}
	}
}

.container {
	&-footer {
		display: flex;
		padding: rem(24) 0 rem(24) rem(24);

		&.icon {
			left: rem(16);

			.text {
				display: none;
			}

			.container-footer-item {
				display: flex;
				position: relative;
				width: rem(40);
				height: rem(40);

				a {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 100%;
					height: 100%;
				}
			}
		}

		&.text {
			.icon {
				display: none;
			}
		}

		&-list {
			display: flex;
			flex-direction: column;
		}

		&-item {
			margin-bottom: rem(8);
			cursor: pointer;

			&:last-of-type {
				margin-bottom: 0;
			}

			a {
				font-weight: $font-weight-bold;
				color: $color-grey-4;
			}
		}
	}
}
</style>