function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['module-item', { active: _vm.isActive }]},[_c('div',{staticClass:"module-item-header",on:{"click":_vm.toggle}},[_c('h2',{staticClass:"module-item-name"},[_vm._v(_vm._s(_vm.checkType)+": "+_vm._s(_vm.items.date))]),_c('div',{staticClass:"module-item-toggle"},[_c('a',[_vm._v(" "+_vm._s(_vm.isActive ? 'Hide detail' : 'Show detail')+" "),_c('FontAwesomeIcon',{attrs:{"icon":['far', 'chevron-circle-down']}})],1)])]),_c('div',{staticClass:"module-item-body container"},[_c('div',{staticClass:"row container-item"},[_c('div',{staticClass:"container-item-body"},[_c('BaseTableCustom',{attrs:{"tableOptions":_vm.tableOptions,"loading":false},scopedSlots:_vm._u([{key:"thead",fn:function(){return [_c('tr',_vm._l((_vm.table.thead),function(ref,index){
var value = ref.value;
var rest$1 = objectWithoutProperties( ref, ["value"] );
var rest = rest$1;
return _c('th',{key:index,class:rest.class},[_c('div',{staticClass:"header-content"},[_vm._v(" "+_vm._s(value)+" "),(rest.key)?_c('BaseSort',{attrs:{"sortName":rest.key,"sortBy":_vm.getSortingBykey(rest.key)},on:{"handleSort":function($event){return _vm.handleSort($event, rest)}}}):_vm._e()],1)])}),0)]},proxy:true},{key:"tbody",fn:function(){return _vm._l((_vm.table.tbody),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(item.shipTo)+" ")]),_c('td',[_vm._v(" #"+_vm._s(item.orderNo)+" ")]),_c('td',[_c('div',{class:['text-date-time', { '-error': item.isUrgent }]},[_vm._v(" "+_vm._s(_vm.showDeliveryDateTime(item))+" "),_c('FontAwesomeIcon',{staticClass:"icon-error",attrs:{"icon":['far', 'exclamation-triangle']}})],1)]),_c('td',[_vm._v(" "+_vm._s(item.shipId)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.device.name)+" ")]),_c('td',{staticClass:"quantity"},[_c('div',{class:['text-quantity', { '-error': _vm.checkErrorQuantity(item.amount, item.minimumVolume) }]},[_vm._v(" "+_vm._s(_vm.showAmount(item))+" "),(_vm.checkErrorQuantity(item.amount, item.minimumVolume))?_c('FontAwesomeIcon',{staticClass:"icon-error",attrs:{"icon":['far', 'exclamation-triangle']}}):_vm._e()],1)]),_c('td',[_c('EOrderStatus',{attrs:{"status":item.status}})],1),_c('td',{},[_c('div',{staticClass:"customization-item"},[_c('div',{staticClass:"customization-item-view-section btn-link"},[_c('router-link',{staticClass:"icon-btn",attrs:{"to":{
												path: ("/e-ordering/info/" + (item.orderNo)),
											},"active-class":"active","exact":""}},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['far', 'eye']}}),_vm._v(" View ")],1)],1),(item.isEditable && _vm.hasManagePermission)?[_c('div',{staticClass:"divider"},[_vm._v("|")]),_c('div',{staticClass:"customization-item-edit-section btn-link"},[_c('router-link',{staticClass:"icon-btn",attrs:{"to":{
													path: ("/e-ordering/edit/" + (item.orderNo))
												},"active-class":"active","exact":""}},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['far', 'edit']}}),_vm._v(" Edit ")],1)],1)]:_vm._e(),(!item.isEditable && _vm.hasManagePermission)?[_c('div',{staticClass:"divider"},[_vm._v("|")]),_c('button',{staticClass:"customization-item-edit-section btn-link",attrs:{"disabled":item.status === _vm.STATUS_LABEL.DELIVERED},on:{"click":_vm.showUnableEditModal}},[_c('div',{staticClass:"icon-btn"},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['far', 'edit']}}),_vm._v(" Edit ")],1)])]:_vm._e()],2)])])})},proxy:true}])})],1)])]),_c('ModalUnableEdit',{attrs:{"modal-name":_vm.MODAL_UNABLE_EDIT,"title":"Unable to edit order","message":_vm.messageModalUnableEdit},on:{"onBack":_vm.hideUnableEditModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }