<template>
	<BaseLayout>
		<BaseBackBtn class="page-back-btn" :to="PAGE_NAME.STANDARD_MODULE_LIST" label="Back to Standard module list"/>
		<StandardModuleOverviewForm :type="standModuleView.STANDARD_CREATE" @submit="handleSendPayload"/>
	</BaseLayout>
</template>

<script>
import { mapActions, mapState } from "vuex";
import BaseLayout from "@/components/BaseLayout.vue";
import BaseBackBtn from "@/components/BaseBackBtn.vue";
import StandardModuleOverviewForm from "@/components/StandardModule/StandardModuleOverviewForm.vue";
import { PAGE_NAME } from "../../enums/pagePermission";
import { TOAST_TYPES } from "../../enums/toast";

import { standModuleView } from "../../enums/standardModule";


export default {
	name: "standardModuleCreate",

	components: {
		BaseLayout,
		BaseBackBtn,
		StandardModuleOverviewForm
	},

	data() {
		return {
			PAGE_NAME,
			standModuleView
		};
	},

	async created() {
		await Promise.all([this.getGraphOptions(), this.getApiTagNameOptions(), this.getApplicationOptions()]);
	},

	computed: {
		...mapState(["grafanaManagement"]),
		...mapState("standardModuleCreate", {
			graphOptions: "graphOptions",
			apiTagNameOptions: "apiTagNameOptions",
			isSucesssCreateStdModuleTemplate: "isSucesssCreateStdModuleTemplate",
			isSuccessCreateStdModuleTemplateData: "isSuccessCreateStdModuleTemplateData"
		})
	},

	methods: {
		...mapActions("toast", {
			showToast: "show"
		}),

		...mapActions("activityLogAdd", {
			addActivityLogStandardModuleCreate: "addActivityLogStandardModuleCreate"
		}),

		...mapActions("standardModuleCreate", {
			sendStandardTemplateCreate: "sendStandardTemplateCreate",
			getGraphOptions: "getGraphOptions",
			getApiTagNameOptions: "getApiTagNameOptions",
			getApplicationOptions: "getApplicationOptions"
		}),

		getApplyToData(forms) {
			return {
				soldTos: forms.soldToValues.filter(({ code }) => code !== "all").map(({ id }) => id),
				shipTos: forms.shipToValues.filter(({ code }) => code !== "all").map(({ id }) => id),
				devices: forms.deviceValues.filter(({ code }) => code !== "all").map(({ id }) => id)
			};
		},

		parseOverviewsCreate(overviews = []) {
			return overviews.map((item, index) => {
				return {
					column_type: item.type,
					api_tag_name: item.apiTagName,
					title: item.title,
					value_min: item.min === "" ? null : item.min ?? null,
					value_max: item.max === "" ? null : item.max ?? null,
					priority: index + 1,
					status: item.status,
					bar_type: null
				};
			}) || [];
		},

		async handleSendPayload({ forms }) {
			const stdModulePayload = {
				title: forms.title,
				app: forms.appValue,
				api_url: forms.apiURL,
				graph_id: forms.graphValue,
				graph_unit: forms.graphUnit,
				graph_min: forms.graphMin === "" ? null : forms.graphMin ?? null,
				graph_max: forms.graphMax === "" ? null : forms.graphMax ?? null,
				graph_api_tags: forms.graphApiTagNames,
				condition_status: forms.status,
				overviews: this.parseOverviewsCreate(forms.overviews),
				applied_to: this.getApplyToData(forms),
				// always disable history status when haven't historyApiURL
				history_status: forms.historyApiURL ? forms.historyStatus : false,
				analysis_status: forms.analysisStatus,
				history_api_url: forms.historyApiURL,
				history: {
					raw_select: forms.selectColumns,
					sql_select: forms.selectColumns.map((item) => { return `${item.select} AS ${item.as}`; }),
					sql_from: forms.from,
					sql_additional_where: forms.whereCondition
				}
			};
			await this.sendStandardTemplateCreate(stdModulePayload);
			if (this.isSucesssCreateStdModuleTemplate) {
				await this.addActivityLogStandardModuleCreate(stdModulePayload.title);
				this.$router.push({
					name: PAGE_NAME.STANDARD_MODULE_LIST
				});
				if (this.isSuccessCreateStdModuleTemplateData.length > 0) {
					const ListDevices = this.isSuccessCreateStdModuleTemplateData.join(", ");
					this.showToast({
						type: TOAST_TYPES.RESPONSE_ERROR,
						props: {
							title: "Not able to apply to all selected device(s)",
							message: `It looks like there is/are some device(s) have been used in other templates.<br>Please remove these device(s) from the current templates that they are using, and then come back to apply to this template again.<br>List of device(s): ${ListDevices}`
						}
					});
				} else {
					this.showToast({
						type: TOAST_TYPES.RESPONSE_SUCCESS,
						props: {
							title: "Create a new template successfully.",
							message: "Your standard template now will be shown here."
						}
					});
				}
			}
		}
	}
};
</script>