<template>
	<BaseLayout :isLoading="eOrderingInfo.isLoading">
		<BaseBackBtn :to="backToEOrderingList" label="Back to order list" />
		<div class="main-order-detail">
			<div class="main-header">
				<div class="title">
					Order detail
				</div>
				<BaseButton
					v-if="isNewStatus && hasManagePermission"
					class="btn-edit"
					size="medium"
					@click="redirectToEdit"
				>
					Edit order
				</BaseButton>
			</div>

			<div class="row row-eq-height">
				<BaseCard class="order-no">
					<template v-slot:header>
						<h4>Order no. #{{ orderNo }}</h4>
						<div class="current-time">Last update: {{ eOrderingInfo.data.createdDate }}</div>
					</template>
					<template v-slot:body>
						<ModuleOverview
							class="module-overview"
							:data="eOrderingInfo.data"
						/>
					</template>
				</BaseCard>
				<ModuleOrderStatus class="order-status" :status="eOrderingInfo.data.status" />
			</div>

			<ModuleLocation
				v-if="isInTransit"
			/>
		</div>
	</BaseLayout>
</template>

<script>
import { mapActions, mapState } from "vuex";
import BaseCard from "@/components/BaseCard.vue";
import BaseBackBtn from "@/components/BaseBackBtn.vue";
import ModuleOrderStatus from "@/components/eOrdering/ModuleOrderStatus.vue";
import ModuleOverview from "@/components/dashboard/modules/ModuleOverview.vue";
import ModuleLocation from "@/components/eOrdering/ModuleLocation.vue";
import { STATUS } from "../../enums/eOrdering/status";
import { MASTERDATA_PERMISSIONS } from "../../enums/masterData";

export default {
	name: "EOrderingDetailView",

	components: {
		BaseCard,
		BaseBackBtn,
		ModuleOrderStatus,
		ModuleOverview,
		ModuleLocation
	},

	data() {
		return {
			orderNo: this.$route.params.id
		};
	},

	computed: {
		...mapState(["eOrderingInfo", "user"]),

		backToEOrderingList() {
			return this.$route.meta.backTo.name;
		},

		isInTransit() {
			return this.eOrderingInfo.data.status === STATUS.IN_TRANSIT;
		},

		hasManagePermission() {
			return this.user.rolePermissions.includes(MASTERDATA_PERMISSIONS.EOrderingManage);
		},

		isNewStatus() {
			return this.eOrderingInfo.data.status === STATUS.NEW;
		}
	},

	created() {
		this.fetchData();
	},

	methods: {
		...mapActions("eOrderingInfo", {
			getEOrderingInfo: "getEOrderingInfo"
		}),

		async fetchData() {
			await this.getEOrderingInfo(this.orderNo);
		},

		redirectToEdit() {
			return this.$router.push({
				path: `/e-ordering/edit/${this.orderNo}`
			});
		}
	}
};
</script>
<style lang="scss" scoped>
.main-order-detail {
	display: flex;
	flex-direction: column;

	.main-header {
		display: flex;
		flex: 1;
		align-items: center;

		.title {
			font-family: $font-family-sub;
			font-size: $font-48;
			width: 100%;
		}

		.btn-edit {
			border-radius: rem(8);
			width: rem(150);
		}
	}

	.row-eq-height,
	.row-eq-height > [class^="col"]:last-of-type {
		@include desktop-large {
			display: flex;
		}

		.order-status {
			display: flex;
			padding-left: rem(8);

			::v-deep {
				.card {
					.card-body {
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}
		}
	}

	.module-overview {
		/* stylelint-disable */
		&::v-deep .overview-bar {
			display: none;
		}
		/* stylelint-enable */
	}
}
</style>
