/**
 * Enum for Graph Name Std Module Config.
 * @readonly
 * @enum {EnumGraphName}
 */
export const GRAPH_NAME = Object.freeze({
	GRAPH_1: "graph1",
	GRAPH_2: "graph2",
	GRAPH_3: "graph3",
	GRAPH_4: "graph4",
	GRAPH_5: "graph5",
	GRAPH_6: "graph6",
	GRAPH_7: "graph7",
	GRAPH_8: "graph8",
	GRAPH_9: "graph9",
	GRAPH_10: "graph10",
	GRAPH_11: "graph11",
	GRAPH_12: "graph12",
	GRAPH_13: "graph13",
	GRAPH_14: "graph14"

});

/**
 * Enum for CONFIG.
 * @readonly
 * @enum {EnumConfig}
 */
export const CONFIG = Object.freeze({
	AUTO_REFRESH_DATA: 180000,
	FILE_SIZE: {
		"500KB": 512000,
		"2MB": 2048000
	},
	EXAMPLE_MODULE_ROUTE: {
		Tank: "tank",
		Meter: "meter",
		ZLD: "zld",
		ZLD2: "zld2",
		CER: "cer",
		NitroFAS: "nitro",
		O2Aeration: "o2-aeration",
		GenGas: "gengas",
		PWR: "pwr",
		SOR: "sor",
		LNG: "lng",
		BHY: "bhy",
		Furnace: "fur",
		AIRCOMP: "air"
	},
	ICON_NAME: {
		LIST_CHECK_ICON: "list-check",
		HAND_HOLDING_BOX_ICON: "hand-holding-box",
		HAND_HOLDING_HEART_ICON: "hand-holding-heart",
		IMAGE_LANDSPACE_ICON: "image-landscape",
		TABLE_LAYOUT_ICON: "table-layout",
		CHART_SIMPLE_ICON: "chart-simple",
		STORE_ICON: "store"
	}
});

/**
 * Enum for Grafana Action.
 * @readonly
 * @enum {EnumGrafanaAction}
 */
export const GRAFANA_ACTION = Object.freeze({
	ADD_GRAFANA_TAB: "GrafanaManagement.Tab.Create",
	EDIT_GRAFANA_TAB: "GrafanaManagement.Tab.Edit",
	DELETE_GRAFANA_TAB: "GrafanaManagement.Tab.Delete",
	ADD_NEW_GRAPH_IN_GRAFANA_TAB: "GrafanaManagement.Graph.Create",
	EDIT_DATA_IN_GRAFANA_TAB: "GrafanaManagement.Graph.Edit",
	EDIT_DATA_TOGGLE_STATUS_GRAFANA: "GrafanaManagement.Graph.Edit.Status",
	DELETE_GRAFANA_DATA_IN_GRAFANA: "GrafanaManagement.Graph.Delete"
});

/**
 * Enum for Default Color
 * @readonly
 * @enum {EnumDefaultColor}
 */
export const DEFAULT_COLOR = Object.freeze({
	CONDITION_STATUS_BG_COLOR: "#000000",
	CONDITION_STATUS_BORDER_COLOR: "#FFFFFF",
	CONDITION_STATUS_TEXT_COLOR: "#FFFFFF",
	LIQUID_LEVEL_BG_COLOR: "FF0000",
	DEVICE_CARD_BORDER_COLOR: "#cad9cf"
});

/**
 * Enum for Status Standard Module Tab.
 * @readonly
 * @enum {EnumStatusStandardModuleTab}
 */
export const STATUS_STANDARD_MODULE_TAB = Object.freeze({
	ACTIVE: 1,
	INACTIVE: 0
});