<template>
	<BaseLayout pageTitle="e-Ordering">
		<router-link v-if="hasPendingOrderList" class="view-button pending-order-link" :to="{ name: PAGE_NAME.E_ORDERING_PENDING_ORDER_LIST }">
			View pending to place orders <span v-if="eOrderingPendingOrderList.hasFailedOrder" class="color-red text-failed">(Failed)</span>
		</router-link>
		<div class="wrapper-select-view">
			<BaseButtonGroup
				v-if="!isRouteTabTypeHistory"
				class="toggle-button-type"
				:items="listViewOptions"
				:active="viewType"
				type="secondary"
				size="small"
				@click="handleSelectView"
			/>

			<BaseButton
				v-if="hasManagePermission"
				class="btn-create-new-order"
				size="large"
				@click="handleOpenDropdownSelectOrderType"
			>
				Create new order
			</BaseButton>
		</div>
		<div class="main-content">
			<div class="row">
				<SearchFilterFormView
					:status="statuses"
					:products="productOptions"
					:query="query"
					@submit="handleSubmitFilter"
					@filterSelect="filterSelectCustom"
				/>
			</div>
			<ul class="nav nav-tabs">
				<li class="nav-item">
					<router-link
						:to="{ name: PAGE_NAME.E_ORDERING_LIST , query: { ...getQueryChangeTab, tabType } }"
						:class="['nav-link', { active: !isRouteTabTypeHistory }]"
						exact
					>
						Order list
					</router-link>
				</li>
				<li	class="nav-item">
					<router-link
						:disabled="isRouteTabTypeHistory"
						:to="{ name: PAGE_NAME.E_ORDERING_LIST, query: { ...getQueryChangeTab, tabType } }"
						:class="['nav-link', { active:  isRouteTabTypeHistory}]"
						exact
					>
						History
					</router-link>
				</li>
			</ul>
			<component
				:is-history="isRouteTabTypeHistory"
				:is="currentTabComponent"
				:view-type="viewType"
				:tab-type="query.tabType"
			/>
		</div>
		<ModalSelectOrderType
			@submit="handleSubmitOrderType"
		/>
	</BaseLayout>
</template>

<script>
import { mapActions, mapState } from "vuex";
import dayjs from "dayjs";
import ListView from "@/views/eOrdering/ListView.vue";
import HistoryView from "@/views/eOrdering/HistoryView.vue";
import BaseButtonGroup from "@/components/BaseButtonGroup.vue";
import BaseLayout from "@/components/BaseLayout.vue";
import SearchFilterFormView from "@/components/eOrdering/SearchFilterFormView.vue";
import ModalSelectOrderType from "@/components/eOrdering/modal/ModalSelectOrderType.vue";

import { cleanParams } from "../../selectors/helpers";
import { dropdownEOrderingProductTypeOptions } from "../../enums/productType";
import { PAGE_NAME } from "../../enums/pagePermission";
import { STATUS, STATUS_LABEL } from "../../enums/eOrdering/status";
import { MASTERDATA_PERMISSIONS } from "../../enums/masterData";
import { ORDER_TYPE } from "../../enums/eOrdering/order";

export default {
	name: "EOrdering",

	components: {
		BaseButtonGroup,
		BaseLayout,
		SearchFilterFormView,
		ModalSelectOrderType
	},

	data() {
		return {
			PAGE_NAME,
			viewType: this.$route.query.view || "delivery",
			listViewOptions: [
				{
					value: "delivery",
					text: "Delivery Date",
					icon: ["far", "grip-horizontal"]
				},
				{
					value: "order",
					text: "Order Date",
					icon: ["far", "list-ul"]
				}
			],
			selectCustom: "",
			query: {
				dateType: this.$route.query.view || "delivery",
				filter: this.$route.query.filter || null,
				page: this.$route.query.page || null,
				q: this.$route.query.q || null,
				status: this.$route.query.status || null,
				productname: this.$route.query.productname || null,
				tabType: this.$route.query.tabType || null,
				startDate: this.$route.query.startDate || null,
				endDate: this.$route.query.endDate || null
			},
			statuses: [
				{ label: "Status: All", value: null },
				{ label: STATUS_LABEL[STATUS.NEW], value: STATUS.NEW },
				{ label: STATUS_LABEL[STATUS.IN_TRANSIT], value: STATUS.IN_TRANSIT },
				{ label: STATUS_LABEL[STATUS.CONFIRMED], value: STATUS.CONFIRMED },
				{ label: STATUS_LABEL[STATUS.CANCELLED], value: STATUS.CANCELLED },
				{ label: STATUS_LABEL[STATUS.COMPLETED], value: STATUS.COMPLETED },
				{ label: STATUS_LABEL[STATUS.DELIVERED], value: STATUS.DELIVERED }
			]
		};
	},
	computed: {
		...mapState(["user", "eOrderingPendingOrderList"]),

		currentTabComponent() {
			if (this.isRouteTabTypeHistory) {
				return HistoryView;
			}

			return ListView;
		},

		productOptions() {
			return [...dropdownEOrderingProductTypeOptions];
		},

		getQueryChangeTab() {
			const query = {
				...this.query,
				...this.$route.query,
				page: null,
				startDate: null,
				endDate: null
			};
			return query;
		},

		tabType() {
			return this.isRouteTabTypeHistory ? "list" : "history";
		},

		isRouteTabTypeHistory() {
			return this.$route.query.tabType === "history";
		},

		isLoading() {
			return this.eOrderingList.isLoading;
		},

		hasManagePermission() {
			return this.user.rolePermissions.includes(MASTERDATA_PERMISSIONS.EOrderingManage);
		},

		hasPendingOrderList() {
			return !this.eOrderingPendingOrderList.isLoading &&
			(!this.eOrderingPendingOrderList.isPendingOrdersEmpty || !this.eOrderingPendingOrderList.isFailedOrdersEmpty);
		}
		// TODO: Waiting for require from customer
		// dropDownStatus() {
		// 	const statuses = [{
		// 		label: "Status: All",
		// 		value: null
		// 	}];

		// 	DROP_DOWN_STATUS[this.$route.query.tabType || "list"].forEach((val) => {
		// 		statuses.push(...status, {
		// 			label: STATUS_LABEL[val],
		// 			value: val
		// 		});
		// 	});
		// 	return statuses;
		// }
	},

	async created() {
		await Promise.all([
			this.getEOrderingConfiguration(),
			this.checkFailedOrders(),
			this.getEOrderingPendingOrderLists()
		]);
	},

	methods: {
		...mapActions("eOrderingInfo", {
			getEOrderingConfiguration: "getEOrderingConfiguration"
		}),

		...mapActions("eOrderingPendingOrderList", {
			checkFailedOrders: "checkFailedOrders",
			getEOrderingPendingOrderLists: "getEOrderingPendingOrderLists"
		}),

		async handleSelectView(value) {
			const query = cleanParams(JSON.parse(JSON.stringify({
				...this.$route.query,
				view: value,
				page: null,
				tabType: null,
				startDate: null,
				endDate: null
			})));

			this.$router.push({
				...this.$route,
				query
			});
		},

		handleChangeQuery() {
			const query = cleanParams(JSON.parse(JSON.stringify({
				...this.$route.query,
				...this.query
			})));

			this.$router.push({ query }).catch(() => {});
		},
		handleSubmitFilter(filters = {}) {
			this.query.q = filters.keyword;
			this.query.productname = filters.product;
			this.query.status = filters.status;
			this.query.page = null;

			this.query.startDate = !filters.dateRange.start ? null : dayjs(filters.dateRange.start).startOf("day").format();
			this.query.endDate = !filters.dateRange.end ? null : dayjs(filters.dateRange.end).endOf("day").format();
			this.query.filter = this.selectCustom || this.query.filter;

			this.handleChangeQuery();
		},

		handleOpenDropdownSelectOrderType() {
			this.$modal.show("modal-select-order-type", {
				orderTypeList: [{
					label: `<b> Normal order </b>`,
					labelHtml: `<b>Normal order </b> <br/> <small>Order with a specific delivery date</small>`,
					value: ORDER_TYPE.NORMAL
				},
				{
					label: "<b>Lot order</b>",
					labelHtml: `<b>Lot order </b> <br/> <small>Order with multiple delivery date</small>`,
					value: ORDER_TYPE.BATCH
				}]
			});
		},

		filterSelectCustom(val) {
			this.selectCustom = val;
		},

		handleSubmitOrderType(orderType) {
			this.$router.push({
				name: PAGE_NAME.E_ORDERING_CREATE,
				query: {
					orderType
				}
			});
		}
	}

};
</script>

<style lang="scss" scoped>
.wrapper-select-view {
	display: flex;
	align-content: center;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: rem(40);
	right: rem(30);

	@include tablet {
		top: rem(108);
	}

	@include mobile {
		flex-direction: column;
		position: relative;
		top: 0;
		right: auto;

		.button-group-container {
			width: 100%;

			&::v-deep .btn {
				font-size: $font-16;
				height: rem(48);
				justify-content: center;
				width: 50%;
			}
		}
	}

	.toggle-button-type {
		margin-right: rem(24);

		@include mobile {
			margin-right: 0;
			margin-bottom: rem(12);
		}
	}

	.btn-create-new-order {
		border-radius: rem(8);

		@include mobile {
			width: 100%;
		}
	}
}

.nav-tabs {
	margin-bottom: rem(12);
}

.pending-order-link {
	position: absolute;
	top: rem(46);
	margin-left: rem(180);

	@include tablet {
		position: initial;
	}
}

.text-failed {
	color: $color-red;
	font-size: $font-20;
}
</style>
