import { render, staticRenderFns } from "./ModalAddNewTab.vue?vue&type=template&id=a6aa9d34&scoped=true&"
import script from "./ModalAddNewTab.vue?vue&type=script&lang=js&"
export * from "./ModalAddNewTab.vue?vue&type=script&lang=js&"
import style0 from "./ModalAddNewTab.vue?vue&type=style&index=0&id=a6aa9d34&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a6aa9d34",
  null
  
)

export default component.exports