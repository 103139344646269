<template>
	<div :class="['furnace-icon', size]">
		<div class="furnace-temperature-wrapper">
			<span :class="['furnace-temperature', {'-is-active': isRegenN }]">N</span>
			<span :class="['furnace-temperature', {'-is-active': isRegenS }]">S</span>
		</div>
		<svg class="svg" width="1em" height="1em" viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg">
			<g fill-rule="nonzero" fill="none">
				<path d="M38.595 42.3c.694.835 1.239 1.344 1.608 1.961a22.192 22.192 0 0 1 2.972 9.347c.317 3.537-2.413 6.782-6.041 6.69-2.185-.053-4.134-1.283-5.227-3.267-.96-1.744-.076-3.242 1.147-4.516 2.356-2.449 4.164-5.165 4.798-8.531.107-.449.389-.864.743-1.684zM27.078 55.8c-1.485-1.357-1.941-2.895-1.871-4.673.135-3.445 1.091-6.628 2.62-9.623.312-.616.814-1.116 1.392-1.904.321.76.557 1.236.732 1.738.513 1.492 1.026 2.985 1.482 4.497.112.378.083.79-.08 1.145a15.565 15.565 0 0 1-1.529 2.142c-1.56 1.843-2.676 3.878-2.746 6.678z" fill="#F79421"/>
				<path d="M62.587 42.3c1.055 1.7 2.048 3.293 3.113 5.012L59.226 50.4 56.7 46.349l5.887-4.049zM44.13 29.7l5.37 2.006-2.955 6.094-4.245-1.59c.581-2.075 1.162-4.146 1.83-6.51zM54.338 34.2l4.162 3.818-4.674 5.182-3.426-3.132c1.274-1.901 2.536-3.777 3.938-5.868zM3.868 53.1 9.9 55.043 8.936 59.4 2.7 58.42c.401-1.824.775-3.538 1.168-5.32zM33.3 28.8h5.4a6454.1 6454.1 0 0 1-.527 6.3h-4.314c-.18-2.086-.365-4.138-.559-6.3zM25.422 37.8 22.5 31.731l5.33-2.031 1.87 6.473zM12.6 37.518l4.06-3.318 4.04 5.313-3.393 2.787zM14.4 46.324 12.134 50.4 6.3 47.351 9.06 42.3zM67.253 52.2l1.147 5.329-6.197.971c-.336-1.448-.656-2.823-1.003-4.325l6.053-1.975zM17.465 23.346H29.43v.054h2.93v-.04h11.912v.04h2.957v-.054h11.911v.054h2.96v-.075H69.3v-1.397c0-.452-.505-.634-1.687-.65-6.341-.088-11.911-.932-16.607-2.604-2.825-1.006-4.712-2.23-4.97-3.739-.166-.96-.052-1.924-.064-2.886V11.7h-21.68v2.767c0 .757-.38 1.482-1.19 2.169-1.842 1.532-4.939 2.588-8.639 3.396a59.012 59.012 0 0 1-9.924 1.227c-1.034.047-1.697.247-1.782.61-.11.478-.027.96-.027 1.475h11.86v.056h2.882l-.007-.054z" fill="#A1A0A0"/>
				<path d="M5.033 45.202h.885c.305-.506.622-1.004.952-1.494H5.033V36.77h5.117v2.801c.74-.8 1.52-1.566 2.333-2.294v-.5h.573a34.965 34.965 0 0 1 1.904-1.52H5.033v-6.943h12.57v5.163a31.841 31.841 0 0 1 2.332-1.303v-3.86h14.9v.07c.347 0 .697-.016 1.047-.016.431 0 .86 0 1.285.025v-.079H52.07v3.984c.793.414 1.57.856 2.332 1.326v-5.31h12.57v6.944H56.8c.656.485 1.29.992 1.904 1.52h3.147v3.06c.762.84 1.481 1.716 2.159 2.63V36.75h2.958v6.937H64.87c.338.5.66 1.006.972 1.522h1.125v2.028A33.48 33.48 0 0 1 69.3 53.1v-27H2.7v26.197a33.55 33.55 0 0 1 2.333-5.539v-1.556z" fill="#A1A0A0"/>
			</g>
		</svg>
		<div class="furnace-value">
			<span class="value">
				{{furnace}}
			</span>
			<span class="unit" v-html="unit"></span>
		</div>
	</div>
</template>
<script>
import { parseAddCommaWhenIsNumber } from "../../../selectors/helpers/index";

export default {
	name: "FurnaceIcon",

	props: {
		value: {
			type: [Number, String],
			default: 0
		},
		regenNS: {
			type: String,
			default: ""
		},
		size: {
			type: String,
			default: "small",
			validator: (value) => {
				return [
					"smallest",
					"small",
					"large"
				].includes(value);
			}
		},
		unit: {
			type: String,
			default: ""
		}
	},

	computed: {
		furnace() {
			return this.value ? parseAddCommaWhenIsNumber(this.value) : "N/A";
		},
		isRegenN() {
			return this.regenNS === "N";
		},
		isRegenS() {
			return this.regenNS === "S";
		}
	}
};
</script>

<style lang="scss" scoped>
$color-temperature-bg: #a3a3a2;

.furnace-icon {
	display: flex;
	flex-wrap: wrap;

	&.small {
		.svg {
			margin-top: rem(-4);
			margin-bottom: rem(-12);

			font-size: rem(80);
		}
	}

	&.large {
		width: rem(146);

		.svg {
			margin-top: rem(-4);
			margin-bottom: rem(-12);

			font-size: rem(124);
		}

		.furnace-temperature-wrapper {
			.furnace-temperature {
				margin-left: rem(8);
				height: rem(24);
				width: rem(24);

				font-size: $font-24;
				font-weight: bold;
			}
		}

		.furnace-value {
			min-width: rem(124);
			max-width: 100%;
			width: initial;

			.value {
				font-size: $font-32;
			}

			.unit {
				font-size: $font-16;
			}
		}
	}

	.furnace-temperature-wrapper {
		display: flex;
		width: 100%;
		justify-content: flex-end;

		.furnace-temperature {
			margin-left: rem(4);
			height: rem(12);
			width: rem(12);
			text-align: center;

			font-size: $font-12;
			line-height: 1;
			color: $color-white;

			background-color: $color-temperature-bg;
			border-radius: 100%;

			&.-is-active {
				background-color: $color-green-haze;
			}
		}
	}

	.furnace-value {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: center;
		width: 100%;

		.value {
			color: $color-black;
			font-size: $font-24;
			font-weight: bold;
			line-height: 1;
		}

		.unit {
			margin-left: rem(4);

			font-size: $font-12;
			color: $color-dark-blue-grey;
			white-space: nowrap;
		}
	}
}
</style>