import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { STATUS_LABEL } from "../../../enums/eOrdering/status";
import { transformShipTo } from "./transformShipTo";
import { transformDevices } from "./transformDevices";
import { transformQuantityRestriction } from "./transformQuantityRestriction";
import { DISPLAY_TIME } from "../../../enums/eOrdering/timeType";

dayjs.extend(utc);

export const transformOrder = (order = {}) => {
	const deliveryDate = dayjs(order?.deliveryDate).format("D MMM YYYY, HH:mm") ?? "-";
	const deliveryTime = dayjs(order?.deliveryDate).format("HH:mm") ?? "-";
	const createdDate = dayjs(order?.createDate).format("D/MM/YY, HH:mm") ?? "-";
	const maximumVolume = transformQuantityRestriction(order?.quantityRestriction)?.optimum ?? 0;
	const minimumVolume = transformQuantityRestriction(order?.quantityRestriction)?.minimum ?? 0;
	const amount = order?.orderAmount ?? "-";
	const device = transformDevices(order?.device) ?? null;
	const product = device?.name ?? null;
	const shipTo = transformShipTo(device?.shipTo)?.name ?? "-";

	return {
		actualAmount: order?.actualAmount ?? "N/A",
		createdDate,
		deliveryDate,
		deliveryTime: DISPLAY_TIME[deliveryTime] || deliveryTime,
		device,
		isUrgent: order?.isUrgent ?? false,
		amount,
		orderNo: order?.orderNo ?? "-",
		poNo: order?.orderPONo ?? "-",
		remark: order?.orderRemark ?? "-",
		status: STATUS_LABEL[order?.orderStatus] ?? "-",
		productName: order?.productName ?? "-",
		shipId: order?.shipId ?? "-",
		shipTo,
		product,
		isEditable: order?.isEditable ?? false,
		maximumVolume,
		minimumVolume
	};
};

export const transformTitleLists = (items) => {
	return {
		date: dayjs(items?.date).format("D MMM YYYY") ?? null,
		orders: items?.orders.map(transformOrder) ?? null
	};
};

export const transformOrderList = (items) => {
	return items?.map(transformTitleLists) ?? {};
};