<template>
	<div :class="['container-detail', iconSize, wrapperClass ]">
		<div class="container-detail-wrapper-header">
			<div class="container-detail-header">{{ data.companyName }}</div>
			<div class="wrapper-location-key">Location key:<span class="location-key">{{ locationKey }}</span></div>
			<div class="pin" @click="onClickPin">
				<FontAwesomeIcon v-if="data.isPinned" class="on" :icon="['fas', 'bookmark']" />
				<FontAwesomeIcon v-else class="off" :icon="['far', 'bookmark']" />
			</div>
			<div class="pin-right">
				<div class="line-sync"></div>
				<div class="sync-alt" @click="onClickSync">
					<img src="/assets/icons/icons-default-font-small@2x.png"
						class="icons-default-font-small">
				</div>
			</div>
		</div>
		<router-link :to="deviceLink" class="link">
			<div class="container-detail-body">
				<div class="container-detail-icon">
					<div class="wrapper-icon">
						<slot name="icon"></slot>
					</div>
				</div>
				<slot name="detail-info"></slot>
				<div v-if="data.lastUpdate !== ''" class="current-time">Last update: {{data.lastUpdate}}</div>
				<div v-else class="inactive">INACTIVE</div>
			</div>
		</router-link>
	</div>
</template>

<script>
import { mapActions } from "vuex";
import { updateDevicePinAPI, updateDeviceUnpinAPI } from "@/services/api/devices.api";

export default {
	name: "BaseDeviceWithCompany",

	props: {
		deviceLink: {
			type: Object,
			required: true
		},
		iconSize: {
			type: String
		},
		data: {
			type: Object,
			required: true,
			default: () => ({})
		},
		wrapperClass: {
			type: String,
			required: false,
			default: ""
		}
	},

	computed: {
		locationKey() {
			return this.data.shipTo.locationKey || this.data.shipTo.key;
		}
	},

	methods: {
		...mapActions("viewDevices", {
			updateDevicePinStatus: "updateDevicePinStatus",
			updateDeviceLoadSync: "updateDeviceLoadSync"
		}),
		onClickPin() {
			if (!this.data.isPinned) {
				this.updateDevicePinStatus({ id: this.data.id, status: true });
				updateDevicePinAPI(this.data.id);
			} else {
				this.updateDevicePinStatus({ id: this.data.id, status: false });
				updateDeviceUnpinAPI(this.data.id);
			}
		},
		async onClickSync() {
			await this.updateDeviceLoadSync(this.data);
		}

	}
};
</script>

<style lang="scss" scoped>
.container-detail {
	@include fontBase();

	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-bottom: rem(16);
	border-radius: 4px;
	border: 1px solid $color-silver;
	height: rem(170);

	&:hover {
		background-color: $color-ice-two;
	}

	.wrapper-location-key {
		display: flex;
		justify-content: flex-end;
		min-width: rem(108);
		margin-right: rem(46);
	}

	.location-key {
		color: $color-dark-blue-grey;
		font-weight: $font-weight-bold;
		margin-left: rem(4);
	}

	&-wrapper-header {
		position: relative;
		display: flex;
		justify-content: space-between;
		width: 100%;
		height: rem(32);
		padding: rem(4) rem(12) 0;
		border-bottom: solid 1px $color-silver;
		overflow: hidden;
		font-size: $font-16;
		color: $color-grey-4;

		.pin {
			position: absolute;
			display: flex;
			justify-content: center;
			align-items: center;
			top: 0;
			right: rem(25);
			width: rem(30);
			height: 100%;
			cursor: pointer;

			svg {
				position: relative;
				top: rem(-1);
				width: rem(9);
				color: red;
			}

			.on {
				color: $color-marigold;
			}

			.off {
				color: $color-dark-blue-grey;
			}
		}

		.pin-right {
			position: absolute;
			top: 0;
			right: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			width: rem(30);
			height: 100%;
			cursor: pointer;

			.line-sync {
				height: 24px;
				width: 1px;
				border-left: 1px solid $color-silver;
			}

			.sync-alt {
				display: flex;
				align-items: center;
			}
		}
	}

	&-header {
		@include ellipsis(1);
	}

	&-body {
		flex: 1;
		position: relative;
		display: flex;
		width: 100%;
		padding-right: rem(11);

		.current-time {
			position: absolute;
			bottom: 2px;
			right: rem(12);
			color: $color-dark-blue-grey;
			font-style: italic;
			font-size: $font-14;
		}

		.inactive {
			position: absolute;
			bottom: rem(4);
			right: rem(10);
			display: flex;
			justify-content: center;
			align-items: center;
			width: rem(50);
			height: rem(18);
			border-radius: rem(4);
			color: $color-grey-4;
			background-color: $color-light-silver;
			font-size: $font-14;
			font-weight: $font-weight-bold;
		}
	}

	&.smallest &-icon {
		width: rem(64);
		height: rem(64);
	}
}

.link {
	width: 100%;
	flex: 1;
	display: flex;
}

.icons-default-font-small {
	width: rem(24);
	height: rem(24);
	margin: -3px 0 0 2px;
	object-fit: contain;
}

</style>