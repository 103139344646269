<template>
	<div>
		<AirCompIcon
			v-if="data.type === GRAPH_NAME.GRAPH_1"
			:key="data.deviceId"
			:value="data.apiTags.apiValue1"
			:secRate="data.apiTags.apiValue2"
			:unit="data.unit"
			:size="iconSize"
			:min="data.min"
			:max="data.max"
		/>
		<PowerIcon
			v-else-if="data.type === GRAPH_NAME.GRAPH_2"
			:kilowatt="data.apiTags.apiValue1"
			:valueForPercentage="data.apiTags.apiValue2"
			:empty="data.apiTags.apiValue1 === undefined"
			:weather="data.weather"
			:size="iconSize"
			:unit="data.unit"
			:min="data.min"
			:max="data.max"
			class="pwr-icon"
		/>
		<CerIcon
			v-else-if="data.type === GRAPH_NAME.GRAPH_3"
			:value="data.apiTags.apiValue1"
			:unit="data.unit"
			:size="iconSize"
			class="cer-icon"
		/>
		<TankIcon
			v-else-if="data.type === GRAPH_NAME.GRAPH_4"
			:status="data.apiTags.apiValue1"
			:percentage="data.apiTags.apiValue2"
			:value="data.apiTags.apiValue3"
			:unit="data.unit"
			:size="iconSize"
			:tankColor="data.apiTags.apiValue1"
			:liquidLevelEmptySoonStatus="data.liquid.liquidLevelEmptySoonStatus"
		/>
		<BulkHydrogenIcon
			v-else-if="data.type === GRAPH_NAME.GRAPH_5"
			:status="data.apiTags.apiValue1"
			:percentage="data.apiTags.apiValue2"
			:value="data.apiTags.apiValue3"
			:unit="data.unit"
			:size="iconSize"
			:tankColor="data.apiTags.apiValue1"
			:liquidLevelEmptySoonStatus="data.liquid.liquidLevelEmptySoonStatus"
		/>
		<MeterIcon
			v-else-if="data.type === GRAPH_NAME.GRAPH_6"
			:value="data.apiTags.apiValue1"
			:percentage="data.apiTags.apiValue2"
			:unit="data.unit"
			:size="iconSize"
		/>
		<GenGasIcon
			v-else-if="data.type === GRAPH_NAME.GRAPH_7"
			:value="data.apiTags.apiValue1"
			:unit="data.unit"
			:percentagePoint="data.apiTags.apiValue2"
			:maxValue="data.maxRange"
			label="Outlet"
			:size="iconSize"
			class="cer-icon"
		/>
		<FurnaceIcon
			v-else-if="data.type === GRAPH_NAME.GRAPH_8"
			:key="data.shipId"
			:value="data.apiTags.apiValue1"
			:regenNS="data.apiTags.apiValue2"
			:unit="data.unit"
			:size="iconSize"
		/>
		<NitroFASIcon
			v-else-if="data.type === GRAPH_NAME.GRAPH_9"
			:value="data.apiTags.apiValue1"
			:unit="data.unit"
			:size="iconSize"
		/>
		<WaterIcon
			v-else-if="data.type === GRAPH_NAME.GRAPH_10"
			:shipId="data.shipId"
			:value="data.apiTags.apiValue1"
			:unit="data.unit"
			color="blue"
			:size="iconSize"
		/>
		<WaterIcon
			v-else-if="data.type === GRAPH_NAME.GRAPH_11"
			:shipId="data.shipId"
			:value="data.apiTags.apiValue1"
			:unit="data.unit"
			color="grey"
			:size="iconSize"
		/>
		<AirCompIconNonFan
			v-else-if="data.type === GRAPH_NAME.GRAPH_12"
			:key="GRAPH_NAME.GRAPH_12"
			:value="data.apiTags.apiValue1"
			:valueForPercentage="data.apiTags.apiValue2"
			:unit="data.unit"
			:size="iconSize"
			:min="data.min"
			:max="data.max"
		/>
		<Graph13Icon
			v-else-if="data.type === GRAPH_NAME.GRAPH_13"
			:key="GRAPH_NAME.GRAPH_13"
			:value="data.apiTags.apiValue1"
			:valueForPercentage="data.apiTags.apiValue2"
			:unit="data.unit"
			:size="iconSize"
			:min="data.min"
			:max="data.max"
		/>
		<Graph14Icon
			v-else-if="data.type === GRAPH_NAME.GRAPH_14"
			:key="GRAPH_NAME.GRAPH_14"
			:value="data.apiTags.apiValue1"
			:valueForPercentage="data.apiTags.apiValue2"
			:unit="data.unit"
			:size="iconSize"
			:min="data.min"
			:max="data.max"
		/>
	</div>
</template>


<script>
import TankIcon from "@/components/StandardModule/standardIcon/TankIcon.vue";
import MeterIcon from "@/components/StandardModule/standardIcon/MeterIcon.vue";
import WaterIcon from "@/components/StandardModule/standardIcon/WaterIcon.vue";
import CerIcon from "@/components/StandardModule/standardIcon/CerIcon.vue";
import GenGasIcon from "@/components/StandardModule/standardIcon/GenGasIcon.vue";
import NitroFASIcon from "@/components/StandardModule/standardIcon/NitroFASIcon.vue";
import PowerIcon from "@/components/StandardModule/standardIcon/PowerIcon.vue";
import FurnaceIcon from "@/components/StandardModule/standardIcon/FurnaceIcon.vue";
import BulkHydrogenIcon from "@/components/StandardModule/standardIcon/BulkHydrogenIcon.vue";
import AirCompIcon from "@/components/StandardModule/standardIcon/AirCompIcon.vue";
import AirCompIconNonFan from "@/components/StandardModule/standardIcon/AirCompIconNonFan.vue";
import Graph13Icon from "@/components/StandardModule/standardIcon/Graph13Icon.vue";
import Graph14Icon from "@/components/StandardModule/standardIcon/Graph14Icon.vue";

import { MODULE_TYPES } from "../enums/modules";
import { GRAPH_NAME } from "../enums/config";

export default {
	name: "BaseStandardIcon",

	components: {
		TankIcon,
		WaterIcon,
		MeterIcon,
		CerIcon,
		NitroFASIcon,
		GenGasIcon,
		PowerIcon,
		BulkHydrogenIcon,
		FurnaceIcon,
		AirCompIcon,
		AirCompIconNonFan,
		Graph13Icon,
		Graph14Icon
	},

	props: {
		data: {
			type: Object,
			default: null
		},
		iconSize: {
			type: String,
			required: true
		}
	},

	data() {
		return {
			MODULE_TYPES,
			GRAPH_NAME
		};
	},

	computed: {
	},

	created() {
	},

	methods: {
	}
};
</script>

<style lang="scss" scoped>
::v-deep p {
	margin: 0;
	font-size: 1em;
	line-height: 1;
}
</style>