<template>
	<form class="filter-form" @submit.prevent="handleSubmit">
		<div :class="['col-md-3 input-group  input-group-search search-shipto', { active: isSearchActive }]">
			<span class="input-icon"><FontAwesomeIcon :icon="['far', 'search']" /></span>
			<BaseFormInput
				v-model="keyword"
				:inputOptions="['ghost input-filter small']"
				placeholder="Order No., Ship-to, D/O No., Tank No., Trailer ID"
				@input="updateKeyword"
				@focus="setFocus('search', true)"
				@blur="setFocus('search', false)"
			/>
		</div>
		<div :class="['col-md-3 input-group input-group-date', { active: isDatePickerActive }]">
			<ModuleDatePickerRange
				:date-input="dateRange"
				:initialDateShortcut="query.filter"
				@active="setFocus('date', $event)"
				@onSubmit="filterDateRange"
				@onFilter="filterSelectCustom"
				@onChange="filterDateRange"
			/>
		</div>
		<div :class="['col-md-3 input-group input-group-product', { active: isProductActive }]">
			<BaseDropdown
				:searchable="false"
				:list="products"
				:value="productId"
				type="ghost"
				placeholder="Product: All"
				@input="filterProduct"
				@active="setFocus('product', $event)"
			/>
		</div>
		<div :class="['col-md-3 input-group input-group-status', { active: isStatusActive }]">
			<BaseDropdown
				:searchable="false"
				:list="status"
				:value="statusId"
				type="ghost"
				placeholder="Status: All"
				@input="filterStatus"
				@active="setFocus('status', $event)"
			/>
		</div>
		<BaseButton
			size="large"
			type="primary"
			outline
			rounded
		>
			Search
		</BaseButton>
		<div class="clear-filter">
			<a href="" class="clear-filter-link" @click.prevent="clearFilter">Clear all</a>
		</div>
	</form>
</template>

<script>
import dayjs from "dayjs";

import ModuleDatePickerRange from "@/components/eOrdering/ModuleDatePickerRange.vue";

export default {
	name: "SearchFilterForm",

	components: {
		ModuleDatePickerRange
	},

	props: {
		status: {
			type: Array,
			default: () => ([])
		},
		products: {
			type: Array,
			default: () => ([])
		},
		query: {
			type: Object,
			default: () => ({})
		}
	},

	data() {
		return {
			keyword: this.query.q,
			productId: this.query.productname,
			statusId: this.query.status,
			dateRange: this.getInitialDate(),
			isStatusActive: false,
			isDatePickerActive: false,
			isProductActive: false,
			isSearchActive: false
		};
	},

	methods: {
		filterProduct(value) {
			this.productId = value;
		},

		filterStatus(value) {
			this.statusId = value;
		},

		filterDateRange(value) {
			this.dateRange = value;
		},

		setFocus(type, value) {
			switch (type) {
				case "status":
					this.isStatusActive = value;
					break;

				case "product":
					this.isProductActive = value;
					break;

				case "search":
					this.isSearchActive = value;
					break;

				case "date":
					this.isDatePickerActive = value;
					break;

				default:
					break;
			}
		},

		handleSubmit() {
			this.$emit("submit", {
				keyword: this.keyword,
				product: this.productId,
				status: this.statusId,
				dateRange: this.dateRange,
				filter: this.query.filter
			});
		},

		clearFilter() {
			this.keyword = null;
			this.productId = null;
			this.statusId = null;
			this.dateRange.start = null;
			this.dateRange.end = null;

			this.$emit("submit", {
				keyword: null,
				product: null,
				status: null,
				dateRange: {
					start: null,
					end: null
				}
			});
		},


		updateKeyword(value) {
			this.keyword = value || null;
		},

		filterSelectCustom(val) {
			this.$emit("filterSelect", val);
		},

		getInitialDate() {
			switch (this.query.filter) {
				case "7d":
					return {
						start: dayjs().subtract(6, "day").toDate(),
						end: dayjs().subtract(0, "day").toDate()
					};
				case "1m":
					return {
						start: dayjs().subtract(1, "month").toDate(),
						end: dayjs().subtract(1, "day").toDate()
					};
				case "3m":
					return {
						start: dayjs().subtract(3, "month").toDate(),
						end: dayjs().subtract(1, "day").toDate()
					};
				case "6m":
					return {
						start: dayjs().subtract(6, "month").toDate(),
						end: dayjs().subtract(1, "day").toDate()
					};
				case "last_1_year":
					return {
						start: dayjs().subtract(1, "year").toDate(),
						end: dayjs().subtract(1, "day").toDate()
					};
				default:
					if (this.query.startDate && this.query.endDate) {
						return {
							start: new Date(this.query.startDate),
							end: new Date(this.query.endDate)
						};
					}
					return {};
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.filter-form {
	@include mobile {
		border-bottom: rem(1) solid $color-silver;
		margin-left: -$grid-gutter-width;
		margin-right: -$grid-gutter-width;
		padding: 0 rem(32);
		width: calc(100% + #{rem(32)});

		.btn.large {
			background-color: $color-emerald;
			border-radius: rem(8);
			color: $color-white;
			width: 100%;
		}

		.input-group {
			background-color: $color-white;
			border-color: $color-silver;
			border-radius: rem(8);
			margin-bottom: rem(12);

			&:not(:last-of-type)::after {
				display: none;
			}

			&::v-deep .dropdown-bubble-container {
				margin-left: 0;
				width: 100%;
			}
		}

		.clear-filter {
			margin: rem(12) 0;
		}

		&::v-deep .input-wrapper {
			&.medium {
				.dropdown-value,
				.dropdown-value-text {
					height: rem(48);
					line-height: rem(48);
				}
			}
		}

		&::v-deep .container-filter {
			width: 100%;

			.container-datepicker {
				width: 100%;

				.custom-input {
					&.medium .input {
						background-color: transparent;
						height: rem(48);
						line-height: rem(48);
					}
				}

				&.position-left > .container-datepicker-control {
					flex-direction: column;
				}

				&.position-left > .container-datepicker-control,
				.container-datepicker-control-inner,
				.container-datepicker-control-footer,
				.container-datepicker-control-footer .custom-done-btn {
					width: 100%;
				}
			}
		}
	}

	@include tablet {
		border-bottom: rem(1) solid $color-silver;
		margin-left: -$grid-gutter-width;
		margin-right: -$grid-gutter-width;
		padding: 0 rem(32);
		width: calc(100% + #{rem(32)});

		.btn.large {
			background-color: $color-emerald;
			border-radius: rem(8);
			color: $color-white;
			width: 100%;
		}

		.clear-filter {
			margin: rem(12) 0;
		}

		.input-group {
			background-color: $color-white;
			border-color: $color-silver;
			border-radius: rem(8);
			margin-bottom: rem(12);

			&:not(:last-of-type)::after {
				display: none;
			}

			&::v-deep .dropdown-bubble-container {
				margin-left: 0;
				width: 100%;
			}
		}

		&::v-deep .container-filter {
			width: 100%;

			.container-datepicker {
				width: 100%;

				.custom-input {
					&.medium .input {
						background-color: transparent;
					}
				}
			}
		}
	}

	.input-group-date {
		width: rem(210);

		@include desktop-large {
			width: rem(228);
		}

		@include tablet {
			width: 32%;
		}

		@include mobile {
			padding: 0;
			width: 100%;
		}
	}

	.input-group-status {
		width: rem(176);

		@include desktop-large {
			width: rem(228);
		}

		@include tablet {
			width: 32%;
		}

		@include mobile {
			padding: 0;
			width: 100%;
		}
	}

	.input-group-product {
		width: rem(176);

		@include desktop-large {
			width: rem(220);
		}

		@include tablet {
			margin-left: 2%;
			margin-right: 2%;
			width: 32%;
		}

		@include mobile {
			margin-left: 0;
			margin-right: 0;
			padding: 0;
			width: 100%;
		}
	}

	.input-group-search {
		width: rem(250);

		@include tablet {
			width: 100%;
		}

		@include mobile {
			width: 100%;
		}
	}
}
</style>
